import { useState, useEffect } from 'react';
import { error } from '../util/Logger';

const HTMLContent = ({ contentDir: contentDir, language }: { contentDir: string, language: string }) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch(`/${contentDir}/${language}.html`)
            .then(response => {
                if (response.ok) {
                    return response.text();
                } else {
                    throw new Error(`Failed to load content: ${response.statusText}`);
                }
            })
            .then(html => setContent(html))
            .catch(e => error(e));
    }, [language]);

    return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default HTMLContent;
