import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/layout/Navbar';
import Footer from '../../components/layout/Footer';
import { CommonTranslation, Translations } from '../../I18N';

const UserRegistrationErrorPage: React.FC = () => {
    const location = useLocation();
    const errorMessage = location.state?.error || 'Unknown error occurred';
    const { t } = CommonTranslation();

    return (
        <div className="full-height">
            <Navbar />
            <div className="container mt-5 flex-grow-1">
                <h1>{t(Translations.UserRegistrationErrorPage.errorDuringRegistration)}</h1>
                <p>{t(Translations.Error.tryAgainLater)}</p>
                <p>{t(Translations.Error.error)} ({t(Translations.Error.thatsAllWeKnow)}): <i>{errorMessage}</i></p>
            </div>
            <Footer />
        </div>
    );
};

export default UserRegistrationErrorPage;
