import React, { useState, useRef, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import EnvVars from '../../EnvVars';
import Card from '../layout/Card';
import { CommonTranslation, Translations } from '../../I18N';
import { handleOAuthInit } from '../../util/HandleOAuthInit';
import './LoginForm.css';

interface LoginFormProps {
    onEmailSend: (email: string, recaptchaToken: string) => Promise<void>;
    onCodeVerify: (email: string, code: string) => Promise<void>;
}

const LoginForm: React.FC<LoginFormProps> = ({ onEmailSend, onCodeVerify }) => {
    const [email, setEmail] = useState('');
    const codeLength = 7;
    const [code, setCode] = useState(Array(codeLength).fill(''));
    const codeInputRefs = useRef<Array<HTMLInputElement | null>>(Array(codeLength).fill(null));
    const [stage, setStage] = useState<'request' | 'verify'>('request');
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const { t } = CommonTranslation();
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);
    const [isSendingGoogle, setIsSendingGoogle] = useState(false);

    useEffect(() => {
        if (stage === 'verify') {
            codeInputRefs.current[0]?.focus();
        }
    }, [stage]);

    const handleEmailSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();
        setIsSendingEmail(true);
        const recaptchaToken = await recaptchaRef.current?.executeAsync();
        if (!recaptchaToken) {
            alert(t(Translations.Captcha.failedToGet));
            setIsSendingEmail(false);
            return;
        }
        await onEmailSend(email, recaptchaToken);
        setStage('verify');
        setIsSendingEmail(false);
    };

    const handleCodeVerification = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        setIsSendingCode(true);
        event.preventDefault();
        const fullCode = code.join('').toLowerCase();
        await onCodeVerify(email, fullCode);
        setIsSendingCode(false);
    };

    const handleGoogleLogin = async () => {
        setIsSendingGoogle(true);
        await handleOAuthInit();
        setIsSendingGoogle(false);
    };

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
        index: number,
    ) => {
        // Single letter input when not copy-pasting
        if (event.key.length === 1 && !event.ctrlKey && !event.metaKey) {
            event.preventDefault();
            const newCode = [...code];
            newCode[index] = event.key.toUpperCase();
            setCode(newCode);
            if (index < codeLength - 1) {
                codeInputRefs.current[index + 1]?.focus();
            }
        }
        else if (event.key === "Backspace") {
            const newCode = [...code];
            newCode[index] = '';
            setCode(newCode);
            codeInputRefs.current[index - 1]?.focus();
        }
        else if (event.key === "Delete") {
            const newCode = [...code];
            newCode[index] = '';
            setCode(newCode);
        }
        else if (event.key === "ArrowLeft") {
            codeInputRefs.current[index - 1]?.focus();
        }
        else if (event.key === "ArrowRight") {
            codeInputRefs.current[index + 1]?.focus();
        }
    };

    const handlePaste = (
        event: React.ClipboardEvent<HTMLInputElement>,
        index: number,
    ) => {
        event.preventDefault();
        const pasteData = event.clipboardData.
            getData('text').slice(0, codeLength - index).toUpperCase();
        const newCode = [...code];
        for (let i = 0; i < pasteData.length && (index + i) < codeLength; i++) {
            newCode[index + i] = pasteData[i];
        }
        setCode(newCode);
        const nextFieldIndex = Math.min(index + pasteData.length, codeLength);
        codeInputRefs.current[nextFieldIndex - 1]?.focus();
    };

    const goToMagicCodeView = () => {
        setStage('verify');
    }

    const goToLoginView = () => {
        setStage('request');
    }

    return (
        <Card
            title={stage === 'request' ? t(Translations.LoginPage.loginWithEmail) : t(Translations.LoginPage.verifyMagicCode)}
            onSubmit={stage === 'request' ? handleEmailSubmit : handleCodeVerification}
        >
            {stage === 'request' ? (
                <>
                    <p>{t(Translations.LoginPage.emailLoginInstructions)}</p>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">{t(Translations.FormField.email)}:</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <ReCAPTCHA
                        sitekey={EnvVars.RECAPTCHA_SITE_KEY}
                        size="invisible"
                        ref={recaptchaRef}
                    />
                    <button type="submit" className="btn btn-primary" disabled={isSendingEmail}>
                        {isSendingEmail ? t(Translations.FormField.sending) : t(Translations.FormField.send)}
                    </button>
                    <div className="mt-2">
                        <a className="btn btn-link" onClick={goToMagicCodeView}>{t(Translations.LoginForm.alreadyHaveMagicCode)}</a>
                    </div>
                    <hr />
                    <button type="button" className="btn btn-primary" onClick={handleGoogleLogin} disabled={isSendingGoogle}>
                        {isSendingGoogle ? t(Translations.FormField.sending) : t(Translations.LoginPage.loginWithGoogle)}
                    </button>
                </>
            ) : (
                <>
                    <div className="mb-3">
                        <p>{t(Translations.LoginPage.magicCodeInstructions)}</p>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">{t(Translations.FormField.email)}:</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <label htmlFor="code" className="form-label">{t(Translations.LoginPage.enterMagicCode)}:</label>
                        <div className="login-form-input-group">
                            {code.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    className="form-control text-uppercase"
                                    value={digit}
                                    onChange={() => { }}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    onPaste={(e) => handlePaste(e, index)}
                                    ref={(el) => codeInputRefs.current[index] = el}
                                    style={{ width: '3rem', textAlign: 'center' }}
                                />
                            ))}
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={isSendingCode}>
                        {isSendingCode ? t(Translations.FormField.sending) : t(Translations.FormField.login)}
                    </button>
                    <div className="mt-2">
                        <a className="btn btn-link" onClick={goToLoginView}>{t(Translations.LoginForm.doNotHaveMagicCode)}</a>
                    </div>
                </>
            )}
        </Card>
    );
};

export default LoginForm;
