import React from "react";
import PlansList from "./PlansList";
import Navbar from "../../components/layout/Navbar";
import Footer from "../../components/layout/Footer";
import { CommonTranslation, Translations } from "../../I18N";

const SelectPlanPage: React.FC = () => {
  const { t } = CommonTranslation();

  return (
    <div className="full-height">
      <Navbar />
      <div className="container mt-5 flex-grow-1 text-center">
        <h1>{t(Translations.SelectPlanPage.title)}</h1>
        <PlansList />
      </div>
      <Footer />
    </div>
  );
};

export default SelectPlanPage;
