import React, { useEffect, useState } from "react";
import "./ConsentPopup.css";
import ConsentWidget from "./ConsentWidget";
import { log } from "../../util/Logger";

const cookieConsentKey = "cookieConsent";

type StoredConsent = {
  essential: boolean;
  google_analytics: boolean;
};

export const loadConsent = function (): StoredConsent {
  const consent = localStorage.getItem(cookieConsentKey);
  if (consent == null) {
    return { essential: true, google_analytics: false };
  } else {
    return JSON.parse(consent);
  }
};

const ConsentPopup: React.FC = () => {
  const [show, setShow] = useState(false);
  const [, setEssentialCookies] = useState(true);
  const [, setGoogleAnalyticsCookies] = useState(true);

  useEffect(() => {
    log("Checking for consent...");
    const consent = localStorage.getItem(cookieConsentKey);
    if (consent === null) {
      log("No consent found, showing consent banner");
      setShow(true);
    } else {
      const consentObj = JSON.parse(consent);
      log("Found consent, not showing banner:", consentObj);
      setEssentialCookies(consentObj.essential);
      setGoogleAnalyticsCookies(consentObj.google_analytics);
    }
  }, []);

  const handleConsent = () => {
    setShow(false);
  };

  return (
    <div
      className={`consent-popup cookie-consent-banner ${
        show ? "show" : "hide"
      }`}
      role="dialog"
      aria-live="polite"
      aria-label="Cookie Consent"
    >
      <ConsentWidget showLanguageSelector={true} onConsent={handleConsent} />
    </div>
  );
};

export default ConsentPopup;
