import React, { useState } from "react";
import { Item } from "../../endpoints/item/get/Endpoint";
import { DirectionAsc, DirectionDesc, Sort } from "../../util/Sort";
import { Page } from "../../util/Page";
import { paths } from "../../Paths";
import { Link } from "react-router-dom";
import "./ItemTable.css";
import { Translations } from "../../locales/translations";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownLong,
  faEye,
  faEyeSlash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

interface TableProps {
  items: Item[];
  totalItems: number;
  currentSort: Sort;
  currentPage: Page;
  onPageChange: (page: Page) => void;
  onSortChange: (sort: Sort) => void;
  onDeleteSelected: (itemIDs: string[]) => void;
  onExportSelected: (itemIDs: string[]) => void;
}

const ItemTable: React.FC<TableProps> = ({
  items,
  totalItems,
  currentSort,
  currentPage,
  onPageChange,
  onSortChange,
  onDeleteSelected,
  onExportSelected,
}) => {
  const { t } = useTranslation("Common");

  const handleSort = (field: string) => {
    const isAsc =
      currentSort.field === field && currentSort.direction === DirectionAsc;
    onSortChange({
      field,
      direction: isAsc ? DirectionDesc : DirectionAsc,
    });
  };

  const formatDate = (nanoTimestamp: number) => {
    const date = new Date(nanoTimestamp / 1000000);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const totalPages = Math.ceil(totalItems / currentPage.limit);
  const currentPageNumber = currentPage.offset / currentPage.limit + 1;

  const changePage = (newPage: number) => {
    const newOffset = (newPage - 1) * currentPage.limit;
    onPageChange({ ...currentPage, offset: newOffset });
  };

  const [showCode, setShowCode] = useState(false);
  const toggleCodeVisibility = () => {
    setShowCode(!showCode);
  };

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newIds = items
        .filter((item) => !selectedItems.includes(item.id))
        .map((item) => item.id);
      setSelectedItems([...selectedItems, ...newIds]);
    } else {
      setSelectedItems(
        selectedItems.filter((id) => !items.map((item) => item.id).includes(id))
      );
    }
  };

  const isAllSelected = (): boolean => {
    if (!items.length) return false;
    return items.every((item) => selectedItems.includes(item.id));
  };

  const handleSelectItem = (id: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    }
  };

  const handleDeleteSelected = () => {
    onDeleteSelected(selectedItems);
    setSelectedItems([]);
  };

  const handleExportSelected = () => {
    onExportSelected(selectedItems);
  };

  const renderHeader = () => (
    <tr>
      <th>
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={isAllSelected()}
        />
        {selectedItems.length ? ` ${selectedItems.length}` : ""}
      </th>
      <th></th>
      <th onClick={() => handleSort("name")} className="sortable">
        {`${t(Translations.ItemDetail.itemName)} `}
        <span className="sort-indicators">
          {currentSort.field === "name"
            ? currentSort.direction === "ASC"
              ? "↑ "
              : "↓ "
            : "↑ ↓"}
        </span>
      </th>
      <th onClick={() => handleSort("created")} className="sortable">
        {`${t(Translations.Generic.created)} `}
        <span className="sort-indicators">
          {currentSort.field === "created"
            ? currentSort.direction === "ASC"
              ? "↑ "
              : "↓ "
            : "↑ ↓"}
        </span>
      </th>
      <th onClick={() => handleSort("updated")} className="sortable">
        {`${t(Translations.Generic.updated)} `}
        <span className="sort-indicators">
          {currentSort.field === "updated"
            ? currentSort.direction === "ASC"
              ? "↑ "
              : "↓ "
            : "↑ ↓"}
        </span>
      </th>
      {/* <th>Description</th> */}
      <th onClick={() => handleSort("item_code")} className="sortable">
        {`${t(Translations.ItemDetail.itemCode)} `}
        <button
          type="button"
          className="btn btn-link p-0 border-0 align-text-bottom"
          onClick={(e) => {
            e.stopPropagation();
            toggleCodeVisibility();
          }}
        >
          <FontAwesomeIcon
            icon={showCode ? faEyeSlash : faEye}
            className="icon-button"
          />
          <span className="ms-1">
            {showCode
              ? t(Translations.Generic.hide)
              : t(Translations.Generic.show)}
          </span>
        </button>
        <span className="sort-indicators">
          {currentSort.field === "item_code"
            ? currentSort.direction === "ASC"
              ? "↑"
              : "↓"
            : "↑ ↓"}
        </span>
      </th>
      <th onClick={() => handleSort("chat_is_unread")} className="sortable">
        {`${t(Translations.RegisteredItemsList.unread)} `}
        <span className="sort-indicators">
          {currentSort.field === "chat_is_unread"
            ? currentSort.direction === "ASC"
              ? "↑ "
              : "↓ "
            : "↑ ↓"}
        </span>
      </th>
    </tr>
  );

  const renderData = () =>
    items.map((item) => (
      <tr key={item.id}>
        <td>
          <input
            type="checkbox"
            onChange={(e) => handleSelectItem(item.id, e.target.checked)}
            checked={selectedItems.includes(item.id)}
          />
        </td>
        <td>
          <Link to={`${paths.item}/${item.id}`}>
            {t(Translations.FormField.edit)}
          </Link>
        </td>
        <td>{item.name}</td>
        <td>{formatDate(item.created)}</td>
        <td>
          {formatDate(item.created) == formatDate(item.updated)
            ? "-"
            : formatDate(item.updated)}
        </td>
        {/* <td>{item.description}</td> */}
        <td>{showCode ? item.item_code : "*".repeat(item.item_code.length)}</td>
        <td>
          {item.chat_is_unread
            ? t(Translations.FormField.true)
            : t(Translations.FormField.false)}
        </td>
      </tr>
    ));

  const renderPagination = () => (
    <nav>
      <ul className="pagination">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <li
            key={page}
            className={`page-item ${
              currentPageNumber === page ? "active" : ""
            }`}
          >
            <button className="page-link" onClick={() => changePage(page)}>
              {page}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead className="thead-light">{renderHeader()}</thead>
        <tbody>{renderData()}</tbody>
      </table>
      {totalItems > currentPage.limit && renderPagination()}
      <Button
        className="me-3"
        onClick={handleDeleteSelected}
        disabled={selectedItems.length === 0}
      >
        {t(Translations.RegisteredItemsList.deleteSelected)}{" "}
        <FontAwesomeIcon icon={faTrashAlt} />
      </Button>
      <Button
        onClick={handleExportSelected}
        disabled={selectedItems.length === 0}
      >
        {t(Translations.RegisteredItemsList.exportSelected)}{" "}
        <FontAwesomeIcon icon={faArrowDownLong} />
      </Button>
    </div>
  );
};

export default ItemTable;
