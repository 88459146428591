import React from "react";
import PlanCard, { planStatusAvailable, planStatusCurrent } from "./PlanCard";
import { usePlans } from "../../util/plans/UsePlan";
import { getPlanByName } from "../../util/plans/Plans";

const PlansList: React.FC = () => {
  const { currentPlan, isLoading, displayPlans } = usePlans();

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
        gap: "20px",
        padding: "20px",
      }}
    >
      {displayPlans.map((plan, index) => (
        <PlanCard
          key={index}
          name={plan.name}
          price={plan.price}
          features={plan.features}
          plan={{
            status:
              displayPlans[index].id === currentPlan
                ? planStatusCurrent
                : planStatusAvailable,
          }}
          canSelect={
            currentPlan ? !getPlanByName(currentPlan)?.isStatic : false
          }
          isLoading={isLoading}
          onSelect={plan.onSelect}
        />
      ))}
    </div>
  );
};

export default PlansList;
