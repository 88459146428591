import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface NotificationProps {
  message: string;
  type: "success" | "error";
  onClose: () => void; // Function to call when the notification is closed
  timeout?: number; // Optional timeout duration in milliseconds
}

const NotificationBar: React.FC<NotificationProps> = ({
  message,
  type,
  onClose,
  timeout = 5000,
}) => {
  useEffect(() => {
    const timer = setTimeout(onClose, timeout);
    return () => clearTimeout(timer);
  }, [onClose, timeout]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        padding: "10px 0",
        backgroundColor: type === "success" ? "#4CAF50" : "#f44336",
        color: "white",
        textAlign: "center",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {message}
      <FontAwesomeIcon
        icon={faClose}
        style={{
          position: "absolute",
          right: "20px",
          top: "50%",
          cursor: "pointer",
          transform: "translateY(-50%)",
        }}
        onClick={onClose}
      />
    </div>
  );
};

export default NotificationBar;
