import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../../util/Endpoints";
import { APIResponse } from "../../../../util/APIResponse";
import HTTPMethod from "../../../../util/HTTPMethod";
import { Requestor } from "../../../../util/Requestor";

export interface Params {
  preference_send_email_on_chat_message: boolean | undefined;
}

export interface Input extends RequestInput {
  body: {
    account: {
      preference_send_email_on_chat_message: boolean | undefined;
    };
  };
}

export interface Output {}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/account",
  method: HTTPMethod.PATCH,
  prepareInput: (params: Params): Input => ({
    body: {
      account: {
        preference_send_email_on_chat_message:
          params.preference_send_email_on_chat_message,
      },
    },
  }),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
