import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from "react";
import { Send as VerifySend } from "../endpoints/account/auth/verify/Endpoint";
import { Send as SendRevoke } from "../endpoints/account/auth/revoke/Endpoint";
import { Send as SendRevokeAuthentications } from "../endpoints/account/auth/revoke_all/Endpoint";
import {
  Send as SendUpdateAccount,
  Params as SendUpdateAccountParams,
  Output as SendUpdateAccountOutput,
} from "../endpoints/account/account/update/Endpoint";
import { error, log } from "./Logger";
import { getAccount } from "./GetAccount";
import { Output as OutputGetAccount } from "../endpoints/account/account/get/Endpoint";
import { APIResponse } from "./APIResponse";

interface AuthContextType {
  isLoggedIn: boolean;
  logout: () => Promise<boolean>;
  logoutAll: () => Promise<boolean>;
  checkAuthStatus: () => void;
  updateAuthStatus: () => Promise<boolean>;
  updateAccountInfo: () => Promise<void>;
  sendUpdateAccount: (
    params: SendUpdateAccountParams
  ) => Promise<APIResponse<SendUpdateAccountOutput>>;
  account: OutputGetAccount | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [account, setAccount] = useState<OutputGetAccount | null>(null);
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      checkAuthStatus();
      mounted.current = true;
    }
  }, []);

  const checkAuthStatus = async (): Promise<boolean> => {
    log("Checking authentication status");
    const apiResponse = await VerifySend({});
    log("Check authentication status response:", apiResponse);

    if (apiResponse.payload?.authenticated) {
      log("User authenticated");
      setIsLoggedIn(true);
      await updateAccountInfo();
      return true;
    } else {
      log("User not authenticated");
      setIsLoggedIn(false);
      return false;
    }
  };

  const updateAuthStatus = async (): Promise<boolean> => {
    return await checkAuthStatus();
  };

  const logout = async (): Promise<boolean> => {
    log("Logging out");
    const apiResponse = await SendRevoke({});
    log("Logout response:", apiResponse);

    if (apiResponse.status === 200) {
      log("User logged out");
      setIsLoggedIn(false);
    } else {
      error("Logout failed");
    }
    setAccount(null);

    return isLoggedIn;
  };

  const logoutAll = async (): Promise<boolean> => {
    log("Revoking all authentications");
    const apiResponse = await SendRevokeAuthentications({});
    log("Revoke authentications response:", apiResponse);

    if (apiResponse.status === 200) {
      log("Revoke authentications success");
      setIsLoggedIn(false);
    } else {
      error("Revoke authentications failed");
    }
    setAccount(null);

    return isLoggedIn;
  };

  const updateAccountInfo = async () => {
    const accountData = await getAccount();
    log("Update account data:", accountData);
    setAccount(accountData);
  };

  const sendUpdateAccount = async (
    data: SendUpdateAccountParams
  ): Promise<APIResponse<SendUpdateAccountOutput>> => {
    log("Sending update account data:", data);
    const apiResponse = await SendUpdateAccount(data);

    if (apiResponse.status === 200) {
      log("Update account success");
    } else {
      error("Update account failed");
    }

    await updateAccountInfo();

    return apiResponse;
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        logout,
        logoutAll,
        checkAuthStatus,
        updateAuthStatus,
        updateAccountInfo,
        account,
        sendUpdateAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
