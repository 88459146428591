import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../util/Endpoints";
import { APIResponse } from "../../../util/APIResponse";
import HTTPMethod from "../../../util/HTTPMethod";
import { Requestor } from "../../../util/Requestor";

export interface Params {
  subscription: {
    name: string;
  };
}

export interface Input extends RequestInput {
  body: Params;
}

export interface Output {
  session_id: string;
  billing_portal_url: string;
}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/subscription",
  method: HTTPMethod.PATCH,
  prepareInput: (params: Params): Input => ({
    body: params,
  }),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
