import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../components/layout/Navbar";
import Footer from "../../components/layout/Footer";
import ItemRegistrationForm from "../../components/dashboard/ItemRegistrationForm";
import RegisteredItemsCards from "../../components/dashboard/RegisteredItemsCards";
import AccountView from "../../components/dashboard/AccountView";
import {
  Send as SendGet,
  Item as ItemGet,
  limitMax,
} from "../../endpoints/item/get/Endpoint";
import {
  Send as SendCreate,
  Item as ItemCreate,
} from "../../endpoints/item/create/Endpoint";
import { Send as SendDelete } from "../../endpoints/item/delete/Endpoint";
import i18n, { CommonTranslation, Translations } from "../../I18N";
import "./DashboardPage.css";
import { error, log } from "../../util/Logger";
import { Sort } from "../../util/Sort";
import { Page } from "../../util/Page";
import ItemTable from "./ItemTable";
import { Button } from "react-bootstrap";
import { usePlans } from "../../util/plans/UsePlan";
import { planDetails } from "../../util/plans/Plans";
import CSVUpload from "../../components/CSVUpload";
import NotificationBar from "../../components/NotificationBar";
import { predicateIn } from "../../util/Predicates";
import { DownloadCSV } from "../../util/DownloadCSV";
import { useAuth } from "../../util/AuthContext";

const DashboardPage: React.FC = () => {
  const [highlightedItemId, setHighlightedItemId] = useState<string | null>(
    null
  );
  const mounted = useRef(false);
  const { t } = CommonTranslation();
  const [activeTab, setActiveTab] = useState("items");

  const [advancedView, setAdvancedView] = useState<boolean>(() =>
    JSON.parse(localStorage.getItem("advancedView") || "false")
  );

  const [showUploadCSVSection, setShowUploadCSVSection] =
    useState<boolean>(false);

  const [items, setItems] = useState<ItemGet[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState<Page>({ offset: 0, limit: 10 });
  const [sort, setSort] = useState<Sort>({
    field: "created",
    direction: "ASC",
  });

  const [notification, setNotification] = useState<{
    type: "success" | "error";
    message: string;
  } | null>(null);

  const { currentPlan, isLoading } = usePlans();
  const [isFetchingItems, setIsFetchingItems] = useState<boolean>(false);

  const [toggleViewButton, setToggleViewButton] = useState<JSX.Element | null>(
    null
  );

  const { account } = useAuth();

  useEffect(() => {
    if (isLoading || !currentPlan) {
      setToggleViewButton(null);
      return;
    }

    if (planDetails[currentPlan].featureItemtable) {
      // Always get first page woth basic view
      let usePage = { offset: 0, limit: 10 };
      if (advancedView) {
        usePage = page;
      }
      fetchSortedPaginatedItems(sort, usePage);

      setToggleViewButton(
        <Button variant="link" onClick={toggleView}>
          {advancedView
            ? t(Translations.DashboardPage.basicView)
            : t(Translations.DashboardPage.advancedView)}
        </Button>
      );
    } else {
      setToggleViewButton(null);
      setAdvancedView(false);
    }

    setShowUploadCSVSection(planDetails[currentPlan].featureUploadCSV);
  }, [isLoading, currentPlan, advancedView]);

  useEffect(() => {
    localStorage.setItem("advancedView", JSON.stringify(advancedView));
  }, [advancedView]);

  useEffect(() => {
    if (!mounted.current) {
      fetchSortedPaginatedItems(sort, page);
      mounted.current = true;
    }
  }, [sort, page]);

  useEffect(() => {
    fetchSortedPaginatedItems(sort, page);
  }, [sort, page]);

  const fetchSortedPaginatedItems = async (sort: Sort, page: Page) => {
    setIsFetchingItems(true);

    const params = {
      selectors: {},
      sorts: [sort],
      page: page,
    };

    log("Fetching items with sort and pagination", params);
    const apiResponse = await SendGet(params);
    log("Fetch items response:", apiResponse);

    if (apiResponse.status === 200 && apiResponse.payload) {
      setItems(apiResponse.payload.items || []);
      setTotalItems(apiResponse.payload.count);
    } else {
      error("Fetch items failed with status: ", apiResponse.status);
    }

    setIsFetchingItems(false);
  };

  const handleNewItem = (newItem: ItemGet) => {
    fetchSortedPaginatedItems(sort, page);
    setHighlightedItemId(newItem.id);
    setTimeout(() => setHighlightedItemId(null), 3000);
  };

  const toggleView = () => setAdvancedView((prev) => !prev);

  const [isCreatingItems, setIsCreatingItems] = useState<boolean>(false);

  const handleNewCSVItems = async (items: ItemGet[]) => {
    setIsCreatingItems(true);

    const processBatch = async (batchItems: ItemGet[]) => {
      const apiResponse = await SendCreate(batchItems);
      if (apiResponse.status === 200) {
        if (apiResponse.payload?.items) {
          apiResponse.payload.items.forEach(
            (createdItem: ItemCreate, index: number) => {
              const sentItem = batchItems[index];
              handleNewItem({
                id: createdItem.id,
                created: createdItem.created,
                updated: createdItem.created,
                name: sentItem.name,
                description: sentItem.description,
                public_info: sentItem.public_info,
                item_code_created: createdItem.item_code_created,
                item_code: createdItem.item_code,
                chat_is_unread: false,
              });
            }
          );
        }
      } else {
        let errorMessage = t(Translations.Error.error);
        if (
          apiResponse.error &&
          apiResponse.error.id === "MAX_ITEM_COUNT_EXCEEDED"
        ) {
          errorMessage = t(
            Translations.ItemRegistrationPage.maximumNumberOfItemsReached
          );
        }
        setNotification({ type: "error", message: errorMessage });
        return false; // Return false if there was an error
      }
      return true; // Return true if successful
    };

    log("Registering items in batches");
    // Split items into batches of 100
    for (let i = 0; i < items.length; i += 100) {
      const batchItems = items.slice(i, i + 100);
      const success = await processBatch(batchItems);
      if (!success) break; // Stop processing if there's an error
    }

    setNotification({
      type: "success",
      message: t(Translations.ItemRegistrationPage.itemsRegisterSuccess),
    });

    setIsCreatingItems(false);
    fetchSortedPaginatedItems(sort, page);
  };

  const uploadCSVSamplesDir = "csv_upload_samples";
  const [uploadCSVSampleFileLink, setUploadCSVSampleFileLink] = useState("");

  useEffect(() => {
    const file = `/${uploadCSVSamplesDir}/${i18n.language}.csv`;
    const fileName = `${t(
      Translations.RegisteredItemsList.sampleFilename
    )}.csv`;
    setUploadCSVSampleFileLink(
      t(Translations.RegisteredItemsList.sampleLink, {
        file: file,
        fileName: fileName,
      })
    );
  }, [i18n.language]);

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const handleDeleteSelected = async (itemIDs: string[]) => {
    const confirmDelete = window.confirm(
      t(Translations.RegisteredItemsList.confirmDelete, {
        count: itemIDs.length,
      })
    );

    if (confirmDelete) {
      log("Deleting items:", itemIDs);

      const apiResponse = await SendDelete({
        selectors: {
          id_in: {
            predicate: predicateIn,
            value: itemIDs,
          },
        },
      });
      log("Delete item response:", apiResponse);

      if (apiResponse.status === 200) {
        log("Delete items success");
        setNotification({
          type: "success",
          message: t(Translations.RegisteredItemsList.deleteSelectedSuccess, {
            count: itemIDs.length,
          }),
        });
      } else {
        error("Delete items failed");
        setNotification({
          type: "error",
          message: t(Translations.RegisteredItemsList.deleteSelectedFailure),
        });
      }

      fetchSortedPaginatedItems(sort, page);
    }
  };

  const handleExportSelected = async (itemIDs: string[]) => {
    log("Exporting items:", itemIDs);
    const batches = chunkArray(itemIDs, limitMax);
    let successfulExports = 0;
    let hasErrors = false;
    let allItems: ItemGet[] = [];

    for (const batch of batches) {
      const apiResponse = await SendGet({
        selectors: {
          id_in: {
            predicate: predicateIn,
            value: batch,
          },
        },
      });

      if (apiResponse.status === 200) {
        log("Batch export success:", batch);
        successfulExports += batch.length;

        if (apiResponse.payload?.items) {
          allItems = allItems.concat(apiResponse.payload.items);
        }
      } else {
        hasErrors = true;
        error("Batch export failed for items:", batch);
        // Continue with the next batch even if one fails
      }
    }

    if (hasErrors) {
      setNotification({
        type: "error",
        message: t(Translations.RegisteredItemsList.exportSelectedFailure),
      });
    } else {
      setNotification({
        type: "success",
        message: t(Translations.RegisteredItemsList.exportSelectedSuccess, {
          count: successfulExports,
        }),
      });

      DownloadCSV(
        preprocessDownloadItems(allItems),
        `${t(Translations.RegisteredItemsList.downloadCSVFilename, {
          date: new Date().toLocaleString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }),
        })}.csv`
      );
    }
  };

  const preprocessDownloadItems = (items: ItemGet[]) => {
    return items.map((item) => ({
      ...item,
      created: formatDateFromNanoTimestamp(item.created),
      updated: formatDateFromNanoTimestamp(item.updated),
      item_code_created: formatDateFromNanoTimestamp(item.item_code_created),
    }));
  };

  const formatDateFromNanoTimestamp = (nanoTimestamp: number) => {
    const date = new Date(nanoTimestamp / 1000000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // months are zero-indexed, add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const chunkArray = (array: any[], size: number) => {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i += size) {
      chunked_arr.push(array.slice(i, i + size));
    }
    return chunked_arr;
  };

  const itemsCountDisplay = (isFetchingItems: boolean, account: any | null) => {
    if (isFetchingItems || !account) {
      return null;
    }

    const maxItems = account.account.feature_max_items;

    return (
      <>
        <p>
          {t(Translations.RegisteredItemsList.totalItems)}:{" "}
          <b>
            {totalItems} / {maxItems}
          </b>
          {totalItems > maxItems && (
            <p className="text-danger">
              {t(Translations.RegisteredItemsList.itemCountOverMax)}
            </p>
          )}
        </p>
      </>
    );
  };

  return (
    <div className="full-height">
      <Navbar />
      {notification && (
        <NotificationBar
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      <div className="container mt-5 flex-grow-1">
        <h2>{t(Translations.Navigation.dashboard)}</h2>
        <ul className="nav mt-3 mb-3 nav-tabs">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "items" ? "active" : ""}`}
              onClick={() => setActiveTab("items")}
            >
              {t(Translations.DashboardPage.navItems)}
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "account" ? "active" : ""}`}
              onClick={() => setActiveTab("account")}
            >
              {t(Translations.DashboardPage.navAccount)}
            </a>
          </li>
        </ul>
        <div>
          {activeTab === "items" && (
            <>
              <ItemRegistrationForm onNewItem={handleNewItem} />
              {showUploadCSVSection ? (
                <div className="upload-section mb-3">
                  <div className="alert alert-info">
                    <strong>
                      {t(Translations.RegisteredItemsList.uploadCSV)}
                    </strong>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: uploadCSVSampleFileLink,
                      }}
                    ></p>
                    <button
                      className="btn btn-link p-0 m-0 align-baseline"
                      onClick={toggleAccordion}
                    >
                      {isAccordionOpen
                        ? `${t(Translations.Generic.hideInstructions)}`
                        : `${t(Translations.Generic.showInstructions)}`}
                    </button>
                    {isAccordionOpen && (
                      <div className="mt-2">
                        <p>
                          {t(
                            Translations.RegisteredItemsList
                              .uploadCSVInstructions
                          )}
                        </p>
                      </div>
                    )}
                    <CSVUpload
                      onNewData={handleNewCSVItems}
                      isDisabled={isCreatingItems}
                      customButtonText={
                        isCreatingItems
                          ? t(Translations.Generic.sending)
                          : undefined
                      }
                    />
                  </div>
                </div>
              ) : null}
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <h3>{t(Translations.RegisteredItemsList.registeredItems)}</h3>
                {toggleViewButton}
              </div>
              {itemsCountDisplay(isFetchingItems, account)}
              {advancedView ? (
                <ItemTable
                  items={items}
                  totalItems={totalItems}
                  currentSort={sort}
                  currentPage={page}
                  onSortChange={setSort}
                  onPageChange={setPage}
                  onDeleteSelected={handleDeleteSelected}
                  onExportSelected={handleExportSelected}
                />
              ) : (
                <RegisteredItemsCards
                  items={items}
                  highlightedItemId={highlightedItemId}
                />
              )}
            </>
          )}
          {activeTab === "account" && <AccountView />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DashboardPage;
