import Navbar from "../../components/layout/Navbar";
import Footer from "../../components/layout/Footer";
import UserRegistrationForm from "../../components/account/UserRegistrationForm";
import { useNavigate } from "react-router-dom";
import { Send } from "../../endpoints/account/account/create/Endpoint";
import { paths } from "../../Paths";
import { error, log } from "../../util/Logger";

interface RegistrationPageProps {
  setIsRegistered: (isRegistered: boolean) => void;
}

const AccountRegistrationPage: React.FC<RegistrationPageProps> = ({
  setIsRegistered,
}) => {
  const navigate = useNavigate();

  const handleRegister = async (email: string, recaptchaResponse: string) => {
    log("Registering user");

    const apiResponse = await Send({
      email: email,
      recaptchaResponse: recaptchaResponse,
    });

    log("User registration response:", apiResponse);

    if (apiResponse.status === 200) {
      log("User registration success");
      setIsRegistered(true);
      navigate(paths.checkEmail);
    } else {
      error("User registration failed");
      // Manage spamming by redirecting to other page
      setIsRegistered(true);
      navigate(paths.registrationError, {
        state: {
          error: apiResponse.errorText,
        },
      });
    }
  };

  return (
    <div className="full-height">
      <Navbar />
      <div className="container flex-grow-1">
        <UserRegistrationForm onRegister={handleRegister} />
      </div>
      <Footer />
    </div>
  );
};

export default AccountRegistrationPage;
