import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../util/Endpoints";
import { APIResponse } from "../../../util/APIResponse";
import HTTPMethod from "../../../util/HTTPMethod";
import { Requestor } from "../../../util/Requestor";

export interface Params {
  item_code: string;
  message: string;
  recaptchaResponse: string;
}

export interface Input extends RequestInput {
  body: {
    chat_message: {
      item_code: string;
      message: string;
    };
  };
  headers: {
    "X-Recaptcha-Response": string;
  };
}

export interface Output {
  chat_message: {
    id: string;
    created: number;
    sent_by_owner: boolean;
  };
}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/chat_message",
  method: HTTPMethod.POST,
  prepareInput: ({ item_code, message, recaptchaResponse }: Params): Input => ({
    body: {
      chat_message: {
        item_code,
        message,
      },
    },
    headers: {
      "X-Recaptcha-Response": recaptchaResponse,
    },
  }),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
