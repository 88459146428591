import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import { useTranslation } from 'react-i18next';
import HTMLContent from '../components/HTMLContent';
import './PrivacyPolicyPage.css';

const PrivacyPolicyPage = () => {
    const { i18n } = useTranslation();

    return (
        <div className="full-height">
            <Navbar />
            <HTMLContent contentDir="privacy_policy" language={i18n.language} />
            <Footer />
        </div>
    );
};

export default PrivacyPolicyPage;
