import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import { Translations as TranslationsInterfaces } from './locales/translations';
import commonEn from './locales/en/Common.json';
import commonFI from './locales/fi/Common.json';
import { error } from './util/Logger';

const resources = {
    en: {
        Common: commonEn
    },
    fi: {
        Common: commonFI
    }
};

const loadInitialLanguage = () => {
    const savedLanguage = localStorage.getItem('appLanguage') || 'en';
    return savedLanguage;
};


i18n
    .use(initReactI18next)
    .init({
        resources, // your resource configuration
        lng: loadInitialLanguage(),
        fallbackLng: false,
        ns: ['Common'], // namespaces
        defaultNS: 'Common', // default namespace
        interpolation: {
            escapeValue: false // React already safes from XSS
        },
        saveMissing: true, // enables the missing key handling functionality
        missingKeyHandler: (lng, ns, key, fallbackValue) => {
            error(`Missing translation - Lang: ${lng}, Namespace: ${ns}, Key: ${key}`);
            throw new Error(`Missing translation - Lang: ${lng}, Namespace: ${ns}, Key: ${key}`);
        }
    });


export default i18n;

export function CommonTranslation() {
    return useTranslation('Common');
}

export function GetCommonTranslation(namespace: string, key: string) {
    return `Common:${namespace}:${key}`
}

export const Translations = TranslationsInterfaces;
