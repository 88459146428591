import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import { useTranslation } from 'react-i18next';
import { faqPageKey, } from '../locales/translations';
import envVariables from '../EnvVars';
import { paths } from '../Paths';

const FAQPage = () => {
    const { t } = useTranslation('Common');
    const faqs = t(
        `${faqPageKey}.questions`,
        {
            returnObjects: true,
            frontendHost: envVariables.FRONTEND_HOST,
            itemChatPath: paths.chat,
            contactPath: paths.contact,
        },
    ) as Array<{ question: string; answer: string }>;

    return (
        <div className="full-height">
            <Navbar />
            <div className="container mt-5 flex-grow-1">
                <h1>{t('FAQPage.title')}</h1>
                <p>{t('FAQPage.description')}</p>
                <div>
                    {faqs.map((faq, index) => (
                        <div key={index}>
                            <h3>{faq.question}</h3>
                            <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default FAQPage;
