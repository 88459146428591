import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../../util/Endpoints";
import { APIResponse } from "../../../../util/APIResponse";
import HTTPMethod from "../../../../util/HTTPMethod";
import { Requestor } from "../../../../util/Requestor";

export interface Params {
  email: string;
  recaptchaResponse: string;
}

export interface Input extends RequestInput {
  body: {
    magic_link_session: {
      email: string;
    };
  };
  headers: {
    "X-Recaptcha-Response": string;
  };
}

export interface Output {
  success: boolean;
}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/magic_link_session",
  method: HTTPMethod.POST,
  prepareInput: ({ email, recaptchaResponse }: Params): Input => ({
    body: {
      magic_link_session: {
        email,
      },
    },
    headers: {
      "X-Recaptcha-Response": recaptchaResponse,
    },
  }),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "omit"
  );
}
