import React, { ReactNode } from 'react';

interface CardProps {
    title: string;
    children: ReactNode;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const Card: React.FC<CardProps> = ({ title, children, onSubmit }) => {
    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card custom-card">
                        <div className="card-body">
                            <h5 className="card-title">{title}</h5>
                            <form onSubmit={onSubmit}>
                                {children}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
