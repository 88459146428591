import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../../util/Endpoints";
import { APIResponse } from "../../../../util/APIResponse";
import HTTPMethod from "../../../../util/HTTPMethod";
import { Requestor } from "../../../../util/Requestor";

export interface Params {
  code: string;
}

export interface Input extends RequestInput {
  url: {
    magic_link_session: {
      code: string;
    };
  };
}

export interface Output {}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/magic_link_session/verify",
  method: HTTPMethod.GET,
  prepareInput: ({ code }: Params): Input => ({
    url: {
      magic_link_session: {
        code,
      },
    },
  }),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
