import React, { useEffect, useState } from "react";
import { Send } from "../../endpoints/item/create/Endpoint";
import { Item } from "../../endpoints/item/get/Endpoint";
import { CommonTranslation, Translations } from "../../I18N";
import { error, log } from "../../util/Logger";
import { usePlans } from "../../util/plans/UsePlan";
import { planDetails } from "../../util/plans/Plans";

const minNameLength = 1;
const nameMaxLength = 200;
const minDescriptionLength = 0;
const maxDescriptionLength = 1000;
const minPublicInfoLength = 0;
const publicInfoMaxLength = 1000;

const ItemRegistrationForm: React.FC<{ onNewItem: (item: Item) => void }> = ({
  onNewItem,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [publicInfo, setPublicInfo] = useState("");
  const [errors, setErrors] = useState<{
    response?: string;
    name?: string;
    description?: string;
    publicInfo?: string;
  }>({});
  const { t } = CommonTranslation();
  const [isSendingRegister, setIsSendingRegister] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSendingRegister(true);

    const newErrors: {
      name?: string;
      description?: string;
      publicInfo?: string;
    } = {};
    if (name.length < minNameLength) {
      newErrors.name = t(Translations.FormField.fieldMustBeAtLeastXCharacters, {
        count: minNameLength,
      });
    }
    if (name.length > nameMaxLength) {
      newErrors.name = t(
        Translations.FormField.fieldMustBeLessThanXCharacters,
        { count: minDescriptionLength }
      );
    }
    if (description.length < minDescriptionLength) {
      newErrors.description = t(
        Translations.FormField.fieldMustBeAtLeastXCharacters,
        { count: minDescriptionLength }
      );
    }
    if (description.length > maxDescriptionLength) {
      newErrors.description = t(
        Translations.FormField.fieldMustBeLessThanXCharacters,
        { count: maxDescriptionLength }
      );
    }
    if (publicInfo.length < minPublicInfoLength) {
      newErrors.publicInfo = t(
        Translations.FormField.fieldMustBeAtLeastXCharacters,
        { count: minPublicInfoLength }
      );
    }
    if (publicInfo.length > publicInfoMaxLength) {
      newErrors.publicInfo = t(
        Translations.FormField.fieldMustBeLessThanXCharacters,
        { count: publicInfoMaxLength }
      );
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSendingRegister(false);
      return;
    }

    log("Registering item:", name, description);
    const apiResponse = await Send([
      {
        name: name,
        description: description,
        public_info: publicInfo,
      },
    ]);
    log("Item registration response:", apiResponse);

    setName("");
    setDescription("");
    setPublicInfo("");
    setErrors({});

    if (apiResponse.status === 200) {
      log("Item registration success");
      if (apiResponse.payload?.items) {
        const item = apiResponse.payload.items[0];
        onNewItem({
          id: item.id,
          created: item.created,
          updated: item.created,
          name: name,
          description: description,
          public_info: publicInfo,
          item_code_created: item.item_code_created,
          item_code: item.item_code,
          chat_is_unread: false,
        });
      }
    } else {
      error("Item registration failed");

      if (
        apiResponse.error &&
        apiResponse.error.id === "MAX_ITEM_COUNT_EXCEEDED"
      ) {
        error("Maximum number of items reached");
        setErrors({
          response: t(
            Translations.ItemRegistrationPage.maximumNumberOfItemsReached
          ),
        });
      }
    }
    setIsSendingRegister(false);
  };

  const { currentPlan, isLoading } = usePlans();
  const [showItemPublicInfo, setShowItemPublicInfo] = useState(false);

  useEffect(() => {
    if (isLoading || !currentPlan) {
      setShowItemPublicInfo(false);
      return;
    }

    setShowItemPublicInfo(planDetails[currentPlan].featureItemPublicInfo);
  }, [isLoading, currentPlan]);

  return (
    <div className="mb-4">
      <h3>{t(Translations.ItemRegistrationPage.registerNewItem)}</h3>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="itemName" className="form-label">
            {t(Translations.ItemDetail.itemName)}
            <br></br>
            <span className="text-muted">
              {` ${t(Translations.ItemDetail.itemDescriptionInstructions)}`}
            </span>
          </label>
          <input
            type="text"
            className={`form-control ${errors.name ? "is-invalid" : ""}`}
            id="itemName"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="itemDescription" className="form-label">
            {t(Translations.ItemDetail.itemDescription)}
            <br></br>
            <span className="text-muted">
              {` ${t(Translations.ItemDetail.itemDescriptionInstructions)}`}
            </span>
          </label>
          <textarea
            className={`form-control ${errors.description ? "is-invalid" : ""}`}
            id="itemDescription"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {errors.description && (
            <div className="invalid-feedback">{errors.description}</div>
          )}
        </div>
        {showItemPublicInfo ? (
          <div className="mb-3">
            <label htmlFor="itemPublicInfo" className="form-label">
              {t(Translations.ItemDetail.itemPublicInfo)}
              <br></br>
              <span className="text-muted">
                {` ${t(Translations.ItemDetail.itemPublicInfoInstructions)}`}
              </span>
            </label>
            <textarea
              className={`form-control ${
                errors.publicInfo ? "is-invalid" : ""
              }`}
              id="itemPublicInfo"
              value={publicInfo}
              onChange={(e) => setPublicInfo(e.target.value)}
            />
            {errors.publicInfo && (
              <div className="invalid-feedback">{errors.description}</div>
            )}
          </div>
        ) : null}
        <button
          type="submit"
          className="btn btn-primary"
          disabled={isSendingRegister}
        >
          {isSendingRegister
            ? t(Translations.FormField.sending)
            : t(Translations.ItemRegistrationPage.registerItem)}
        </button>
        {errors.response && (
          <div className="alert alert-danger mt-3">{errors.response}</div>
        )}
      </form>
    </div>
  );
};

export default ItemRegistrationForm;
