import { useTranslation } from 'react-i18next';
import { CommonTranslation, Translations } from '../../I18N';

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const { t } = CommonTranslation();

    const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newLanguage = event.target.value;
        i18n.changeLanguage(newLanguage);
        localStorage.setItem('appLanguage', newLanguage);
    };

    return (
        <div className="language-selector">
            <label htmlFor="language-select">{t(Translations.LanguageSelector.language)}:</label>
            <select
                id="language-select"
                className="form-select"
                value={i18n.language}
                onChange={changeLanguage}
                style={{ width: 'auto', display: 'inline-block', marginLeft: '10px' }}
            >
                <option value="en">{t(Translations.LanguageSelector.english)}</option>
                <option value="fi">{t(Translations.LanguageSelector.finnish)}</option>
            </select>
        </div>
    );
};

export default LanguageSelector;
