import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";
import { CommonTranslation, Translations } from "../../I18N";
import "./Footer.css";
import { paths } from "../../Paths";
import envVariables from "../../EnvVars";

const Footer = () => {
  const { t } = CommonTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-light text-center text-lg-start mt-auto pt-5">
      <Container
        fluid
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        <Row className="d-flex align-items-center justify-content-center">
          <Col md={4} className="mb-2">
            <div className="footer-section">
              <a
                className="footer-link text-dark"
                href={envVariables.FRONTEND_HOST}
              >
                © {currentYear} HyperItem.com
              </a>
            </div>
          </Col>
          <Col md={4} className="mb-2 d-flex flex-column align-items-center">
            <div className="footer-section">
              {envVariables.SHOW_PLANS ? (
                <Link to={paths.selectPlan} className="footer-link text-dark">
                  {t(Translations.Navigation.selectPlan)}
                </Link>
              ) : null}
            </div>
            <div className="footer-section">
              <Link to={paths.privacyPolicy} className="footer-link text-dark">
                {t(Translations.Navigation.privacyPolicy)}
              </Link>
              <Link to={paths.cookiePolicy} className="footer-link text-dark">
                {t(Translations.Navigation.cookiePolicy)}
              </Link>
            </div>
            <div className="footer-section">
              <Link to={paths.faq} className="footer-link text-dark">
                {t(Translations.Navigation.faq)}
              </Link>
              <Link to={paths.contact} className="footer-link text-dark">
                {t(Translations.Navigation.contact)}
              </Link>
            </div>
          </Col>
          <Col md={4} className="mb-2 d-flex flex-column align-items-center">
            <div className="footer-section">
              <LanguageSelector />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
