import React, { createContext, useState, useContext, useEffect } from 'react';
import { initializeAnalytics, disableAnalytics } from './GoogleAnalytics';
import { loadConsent } from '../components/consent/ConsentPopup';
import envVariables from '../EnvVars';
import { log } from './Logger';

interface AnalyticsContextProps {
    isGoogleAnalyticsEnabled: boolean;
    enableAnalytics: () => void;
    disableAnalytics: () => void;
}

const AnalyticsContext = createContext<AnalyticsContextProps | undefined>(undefined);


const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAnalyticsEnabled, setIsAGooglenalyticsEnabled] = useState(false);

    useEffect(() => {
        const consent = loadConsent();
        log("Checking analytics consent:", consent.google_analytics);

        if (consent.google_analytics == true) {
            setIsAGooglenalyticsEnabled(true);
            initializeAnalytics(envVariables.GOOGLE_ANALYTICS_ID);
        }
    }, []);


    const enableGoogleAnalytics = () => {
        setIsAGooglenalyticsEnabled(true);
        initializeAnalytics(envVariables.GOOGLE_ANALYTICS_ID);
    };

    const disableGoogleAnalytics = () => {
        setIsAGooglenalyticsEnabled(false);
        disableAnalytics(envVariables.GOOGLE_ANALYTICS_ID);
    };

    return (
        <AnalyticsContext.Provider value={{
            isGoogleAnalyticsEnabled: isAnalyticsEnabled,
            enableAnalytics: enableGoogleAnalytics
            , disableAnalytics: disableGoogleAnalytics
        }}>
            {children}
        </AnalyticsContext.Provider>
    );
};

const useAnalytics = () => {
    const context = useContext(AnalyticsContext);
    if (!context) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider');
    }
    return context;
};

export { AnalyticsProvider, useAnalytics };
