import React, { useEffect, useState, useRef } from "react";
import {
  Send as GetMessagesSend,
  ChatMessage,
  SortFieldCreated,
  Output,
} from "../../endpoints/chat_message/get_by_item_code/Endpoint";
import { Send as SendMessageSend } from "../../endpoints/chat_message/create/Endpoint";
import { equal } from "../../util/Predicates";
import { CommonTranslation, Translations } from "../../I18N";
import { useTranslation } from "react-i18next";
import { DirectionAsc, DirectionDesc } from "../../util/Sort";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import EnvVars from "../../EnvVars";
import {
  getPaginatedData,
  mergePaginatedData,
} from "../../util/DataPaginationHandler";
import { APIResponse } from "../../util/APIResponse";
import { error, log } from "../../util/Logger";

interface ChatSectionProps {
  itemCode: string;
  onSuccess?: () => void;
  useReCaptcha?: boolean;
}

const minMessageLength = 1;
const maxMessageLength = 1024;
const getCount = 10;

const ChatSection: React.FC<ChatSectionProps> = ({
  itemCode,
  onSuccess,
  useReCaptcha,
}) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const { t } = CommonTranslation();
  const { i18n } = useTranslation();
  const [offset, setOffset] = useState(0);
  const [moreAvailable, setMoreAvailable] = useState(true);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [errors, setErrors] = useState<{
    message?: string;
    get_messages?: string;
    send_message?: string;
  }>({});
  const [errorKeys, setErrorKeys] = useState<{
    message?: { key: string; params: { [key: string]: any } };
    get_messages?: string;
    send_message?: string;
  }>({});
  const mounted = useRef(false);
  const [isGettingMessages, setIsGettingMessages] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  useEffect(() => {
    if (!mounted.current) {
      handleGetMessages(itemCode, offset, getCount);
      mounted.current = true;
    }
  }, [itemCode, getCount]);

  useEffect(() => {
    const newErrors: {
      message?: any;
      get_messages?: string;
      send_message?: string;
    } = {};
    if (errorKeys.message) {
      newErrors.message = t(errorKeys.message.key, errorKeys.message.params);
    }
    if (errorKeys.get_messages) {
      newErrors.get_messages = t(errorKeys.get_messages);
    }
    if (errorKeys.send_message) {
      newErrors.send_message = t(errorKeys.send_message);
    }
    setErrors(newErrors);
  }, [i18n.language, errorKeys, t]);

  const getLatestMessages = async () => {
    setIsGettingMessages(true);
    await handleGetLatestMessages(getCount);
    setIsGettingMessages(false);
  };

  const getOldMessages = async () => {
    await handleGetMessages(itemCode, offset, getCount);
    setOffset(offset + getCount);
  };

  const sendGetMessages = async (
    itemCode: string,
    offset: number,
    limit: number,
    direction: "ASC" | "DESC"
  ): Promise<APIResponse<Output>> => {
    const apiResponse = await GetMessagesSend({
      selectors: {
        item_code: {
          predicate: equal,
          value: itemCode,
        },
      },
      sorts: [
        {
          field: SortFieldCreated,
          direction: direction,
        },
      ],
      page: {
        limit,
        offset,
      },
    });
    log("Get messages response:", apiResponse);

    return apiResponse;
  };

  const handleGetLatestMessages = async (limit: number) => {
    let latestFetched: ChatMessage[] = [];
    let offset = 0;

    let foundPreviousMessage = false;
    while (!foundPreviousMessage) {
      const apiResponse = await sendGetMessages(
        itemCode,
        offset,
        limit,
        DirectionDesc
      );

      if (apiResponse.status !== 200) {
        error("Get messages failed");
        setErrorKeys({
          get_messages: Translations.ChatSection.itemNotFoundWithItemCode,
        });
        break;
      }

      const { payload } = apiResponse;
      if (!payload) {
        error("Missing payload");
        return;
      }

      latestFetched = [...latestFetched, ...payload.chat_messages];
      offset = offset + payload.chat_messages.length;

      if (
        payload.chat_messages.length == 0 ||
        payload.chat_messages.length < limit ||
        messages.length == 0
      ) {
        break;
      }

      // Stop if first message ID is among fetched messages
      for (let i = 0; i < payload.chat_messages.length; i++) {
        if (payload.chat_messages[i].id === messages[0]?.id) {
          foundPreviousMessage = true;
          break;
        }
      }
    }

    setMessages(
      mergePaginatedData(messages, latestFetched, "id", "created", "desc")
    );
  };

  const handleGetMessages = async (
    itemCode: string,
    offset: number,
    limit: number
  ) => {
    setIsGettingMessages(true);

    try {
      const apiResponse = await sendGetMessages(
        itemCode,
        offset,
        limit,
        DirectionDesc
      );

      if (apiResponse.status === 200 && apiResponse.payload?.chat_messages) {
        log("Get messages success");

        getPaginatedData<ChatMessage>(
          apiResponse.payload.chat_messages,
          messages,
          setMessages,
          "id",
          "created",
          "desc",
          {
            totalFetched: apiResponse.payload.chat_messages.length,
            limit,
            offset: offset,
            updateOffset: setOffset,
            setMoreAvailable: setMoreAvailable,
          }
        );
        onSuccess && onSuccess();
      } else {
        error("Get messages failed");
        setErrorKeys({
          get_messages: Translations.ChatSection.itemNotFoundWithItemCode,
        });
      }
    } catch (e) {
      error("Get messages error:", e);
      setErrorKeys({
        get_messages: Translations.ChatSection.errorLoadingMessages,
      });
    } finally {
      setLoading(false);
    }

    setIsGettingMessages(false);
  };

  const submitMessage = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSendingMessage(true);

    if (newMessage.trim().length < minMessageLength) {
      setErrorKeys({
        message: {
          key: Translations.FormField.fieldMustBeAtLeastXCharacters,
          params: { count: minMessageLength },
        },
      });
      setIsSendingMessage(false);
      return;
    } else if (newMessage.trim().length > maxMessageLength) {
      setErrorKeys({
        message: {
          key: Translations.FormField.fieldMustBeLessThanXCharacters,
          params: { count: maxMessageLength },
        },
      });
      setIsSendingMessage(false);
      return;
    }

    setErrorKeys({});
    await handleSendMessage();
    setIsSendingMessage(false);
  };

  const handleSendMessage = async () => {
    log("Sending message:", newMessage);

    let recaptchaToken = "";
    if (useReCaptcha) {
      const getReptchaToken = await recaptchaRef.current?.executeAsync();
      if (!getReptchaToken) {
        const errorKey = Translations.Captcha.failedToGet;
        setErrorKeys({ send_message: errorKey });
        return;
      }
      recaptchaToken = getReptchaToken;
    }
    const apiResponse = await SendMessageSend({
      item_code: itemCode,
      message: newMessage,
      recaptchaResponse: recaptchaToken,
    });
    log("Send message response:", apiResponse);

    if (apiResponse.status === 200) {
      log("Send message success");

      const { payload } = apiResponse;
      if (!payload) {
        error("Missing payload");
        return;
      }

      setMessages((prevMessages) => [
        {
          id: payload.chat_message.id,
          created: payload.chat_message.created,
          message: newMessage,
          sent_by_owner: payload.chat_message.sent_by_owner,
        },
        ...prevMessages,
      ]);
      setNewMessage("");
    } else {
      error("Send message failed");
      const errorKey = Translations.ChatSection.failedToSendMessage;
      setErrorKeys({ send_message: errorKey });
    }
  };

  return (
    <div>
      {loading ? (
        <div>{t(Translations.Generic.loading)}</div>
      ) : errors.get_messages ? (
        <div>{errors.get_messages}</div>
      ) : (
        <div>
          <button
            className="btn btn-secondary mb-2"
            onClick={getLatestMessages}
            disabled={isGettingMessages}
          >
            {isGettingMessages
              ? t(Translations.Generic.loading)
              : t(Translations.ChatSection.refresh)}
          </button>
          <form onSubmit={submitMessage}>
            <div className="input-group mb-3">
              <input
                type="text"
                className={`form-control ${errors.message ? "is-invalid" : ""}`}
                placeholder={t(Translations.ChatSection.typeMessage)}
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSendingMessage}
                >
                  {isSendingMessage
                    ? t(Translations.Generic.sending)
                    : t(Translations.FormField.send)}
                </button>
              </div>
              <div className="invalid-feedback">{errors.message}</div>
            </div>
            {useReCaptcha && (
              <ReCAPTCHA
                sitekey={EnvVars.RECAPTCHA_SITE_KEY}
                size="invisible"
                ref={recaptchaRef}
              />
            )}
          </form>
          {errors.send_message && (
            <div className="alert alert-danger">{errors.send_message}</div>
          )}
          <ul className="list-group mb-3">
            {messages.map((msg, index) => (
              <li key={index} className="list-group-item">
                <strong>
                  {msg.sent_by_owner
                    ? t(Translations.ChatSection.owner)
                    : t(Translations.ChatSection.finder)}
                </strong>
                : {msg.message} <br />
                <small>
                  {new Date(msg.created / 1_000_000).toLocaleString()}
                </small>
              </li>
            ))}
          </ul>
          <button
            className="btn btn-secondary mb-3"
            onClick={getOldMessages}
            disabled={!moreAvailable || isGettingMessages}
          >
            {isGettingMessages
              ? t(Translations.Generic.loading)
              : moreAvailable
              ? t(Translations.ChatSection.viewOlderMessages)
              : t(Translations.ChatSection.noMoreMessages)}
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatSection;
