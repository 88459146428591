import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../../components/layout/Navbar';
import Footer from '../../components/layout/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { Send } from '../../endpoints/account/oauth/callback/Endpoint';
import { useAuth } from '../../util/AuthContext';
import { paths } from '../../Paths';
import { CommonTranslation, Translations } from '../../I18N';
import { error, log } from '../../util/Logger';

const OAuthCallbackPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const mounted = useRef(false);
    const { updateAuthStatus } = useAuth();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { t } = CommonTranslation();

    useEffect(() => {
        if (!mounted.current) {
            const queryParams = new URLSearchParams(location.search);
            const code = queryParams.get('code');
            const state = queryParams.get('state');

            if (code && state) {
                handleOAuthCallback(code, state);
            } else {
                setErrorMessage('Invalid OAuth response');
            }
            mounted.current = true;
        }
    }, [location]);

    const handleOAuthCallback = async (code: string, state: string) => {
        log("Processing OAuth callback");

        const apiResponse = await Send(
            {
                code: code,
                state: state,
                callbackURL: window.location.href,
            }
        );

        log("OAuth callback response:", apiResponse);

        if (apiResponse.status === 200) {
            log("OAuth callback success");
            handleLoginSuccess();
        } else {
            error("OAuth callback failed");
            setErrorMessage(apiResponse.errorText || 'OAuth callback failed');
        }
    };

    const handleLoginSuccess = async () => {
        await updateAuthStatus();
        navigate(paths.dashboard);
    };

    return (
        <div className="full-height">
            <Navbar />
            <div className="container mt-5 flex-grow-1">
                {errorMessage ? (
                    <div className="container mt-5 flex-grow-1">
                        <h1>{t(Translations.OAuthCallbackPage.errorProcessingRequest)}</h1>
                        <p>{t(Translations.Error.tryAgainLater)}</p>
                        <p>{t(Translations.Error.error)} ({t(Translations.Error.thatsAllWeKnow)}): <i>{errorMessage}</i></p>
                    </div>
                ) : (
                    <h2>Processing...</h2>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default OAuthCallbackPage;
