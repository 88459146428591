import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../../util/Endpoints";
import { APIResponse } from "../../../../util/APIResponse";
import HTTPMethod from "../../../../util/HTTPMethod";
import { Requestor } from "../../../../util/Requestor";

export interface Params {
  state: string;
  code: string;
  callbackURL: string;
}
export interface Input extends RequestInput {
  url: {
    state: string;
    code: string;
    callback_url: string;
  };
}

export interface Output {}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/oauth/callback",
  method: HTTPMethod.GET,
  prepareInput: ({ state, code, callbackURL }) => ({
    url: {
      state,
      code,
      callback_url: callbackURL,
    },
  }),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
