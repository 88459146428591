import { Endpoint, RequestInput } from './Endpoints';
import { IRequestor } from './IRequestor';
import { APIResponse, APIError } from './APIResponse';
import { error, log } from './Logger';

class Requestor<
    Input extends RequestInput,
    Output
> implements IRequestor<Input, Output> {
    async SendRequest(
        endpoint: Endpoint<Input>,
        credentials: RequestCredentials,
    ): Promise<APIResponse<any>> {
        const url = this.constructUrlWithParams(
            endpoint.url,
            endpoint.input.url,
        );

        log(
            'Sending request to:', endpoint.url,
            "\nMethod:", endpoint.method,
            "\nFull input:", endpoint.input,
            "\nURL:", url,
            "\nBody:", endpoint.input.body,
            "\nHeaders:", endpoint.input.headers
        );

        try {
            const response = await fetch(
                url,
                {
                    credentials,
                    method: endpoint.method,
                    headers: {
                        'Content-Type': 'application/json',
                        ...endpoint.input.headers,
                    },
                    body: this.prepareBody(endpoint.input.body)
                },
            );

            if (response.ok) {
                const data: APIResponse<Output> = await response.json();
                return {
                    payload: data.payload,
                    status: response.status,
                };
            } else {
                const errorText = await response.text();
                try {
                    const data: APIResponse<Output> = JSON.parse(errorText);
                    const apiError: APIError | undefined = data.error;
                    log('Response returned error:', apiError);
                    return {
                        payload: data.payload,
                        error: apiError,
                        errorText: apiError?.id,
                        status: response.status,
                    };
                } catch (e) {
                    error(
                        'Response returned a non-JSON response:',
                        errorText,
                    );
                    return {
                        errorText: errorText,
                        status: response.status,
                    };
                }
            }
        } catch (e: any) {
            error('Network error:', e.message);
            return {
                errorText: e.message,
            };
        }
    }

    private prepareBody(body?: any): string | null {
        return body ? JSON.stringify(body) : null;
    }


    private constructUrlWithParams(
        url: string,
        params?: Record<string, any>,
    ): string {
        if (!params) return url;
        const flattenedParams = this.flattenParams(params);
        const queryString = this.encodeQueryString(flattenedParams);
        return `${url}?${queryString}`;
    }

    private flattenParams(
        params: Record<string, any>,
        prefix: string = '',
    ): Record<string, string> {
        let result: Record<string, string> = {};
        for (const key in params) {
            const value = params[key];
            const prefixedKey = prefix ? `${prefix}.${key}` : key;
            if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
                Object.assign(result, this.flattenParams(value, prefixedKey));
            } else if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    const arrayPrefix = `${prefixedKey}[${index}]`;
                    if (typeof item === 'object') {
                        Object.assign(result, this.flattenParams(item, arrayPrefix));
                    } else {
                        result[arrayPrefix] = encodeURIComponent(item.toString());
                    }
                });
            } else {
                if (!value) continue;
                result[prefixedKey] = encodeURIComponent(value.toString());
            }
        }
        return result;
    }

    private encodeQueryString(params: Record<string, string>): string {
        return Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${params[key]}`)
            .join('&');
    }
}

export { Requestor };
