import React, { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import EnvVars from '../../EnvVars';
import Card from '../layout/Card';
import { CommonTranslation, Translations } from '../../I18N';
import { handleOAuthInit } from '../../util/HandleOAuthInit';

interface UserRegistrationFormProps {
    onRegister: (email: string, recaptchaToken: string) => Promise<void>;
}

const UserRegistrationForm: React.FC<UserRegistrationFormProps> = ({ onRegister }) => {
    const [email, setEmail] = useState('');
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const { t } = CommonTranslation();
    const [isSendingForm, setIsSendingForm] = useState(false);
    const [isSendingGoogle, setIsSendingGoogle] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSendingForm(true);

        if (!email) {
            alert(t(Translations.FormField.pleaseEnterEmail))
            setIsSendingForm(false);
            return;
        }
        if (!recaptchaRef.current) {
            alert(t(Translations.Captcha.failedToLoad));
            setIsSendingForm(false);
            return;
        }
        const recaptchaToken = await recaptchaRef.current.executeAsync();
        if (!recaptchaToken) {
            alert(t(Translations.Captcha.failedToGet));
            setIsSendingForm(false);
            return;
        }

        await onRegister(email, recaptchaToken || "");
        setIsSendingForm(false);
    };

    const handleGoogleRegister = async () => {
        setIsSendingGoogle(true);
        await handleOAuthInit();
        setIsSendingGoogle(false);
    };

    return (
        <Card
            title={t(Translations.UserRegistrationForm.title)}
            onSubmit={handleSubmit}
        >
            <div className="mb-3">
                <label htmlFor="email" className="form-label">{t(Translations.FormField.email)}:</label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <ReCAPTCHA
                sitekey={EnvVars.RECAPTCHA_SITE_KEY}
                size="invisible"
                ref={recaptchaRef}
            />
            <button type="submit" className="btn btn-primary" disabled={isSendingForm}>
                {isSendingForm ? t(Translations.FormField.sending) : t(Translations.FormField.register)}
            </button>
            <hr></hr>
            <button type="button" className="btn btn-primary" onClick={handleGoogleRegister} disabled={isSendingGoogle}>
                {t(Translations.UserRegistrationForm.registerWithGoogle)}
            </button>
        </Card>
    );
};

export default UserRegistrationForm;
