import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../util/Endpoints";
import { APIResponse } from "../../../util/APIResponse";
import HTTPMethod from "../../../util/HTTPMethod";
import { Requestor } from "../../../util/Requestor";
import { Selectors } from "../Selectors";
import { Page } from "../../../util/Page";
import { Sort } from "../../../util/Sort";

export interface Params {
  selectors: Selectors;
  sorts?: Sort[];
  page?: Page;
}

export const SortFieldCreated: "created" = "created";

export interface Input extends RequestInput {
  url: {
    selectors: Selectors;
    sorts?: Sort[] | undefined;
    page?: Page | undefined;
  };
}

export interface Output {
  chat_messages: ChatMessage[];
}

export interface ChatMessage {
  id: string;
  created: number;
  message: string;
  sent_by_owner: boolean;
}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/chat_message/by_item_code",
  method: HTTPMethod.GET,
  prepareInput: ({ selectors, sorts, page }: Params): Input => ({
    url: {
      selectors,
      sorts,
      page,
    },
  }),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
