import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useAnalytics } from '../../util/GoogleAnalyticsContext';
import './ConsentPopup.css';
import { CommonTranslation, Translations } from '../../I18N';
import LanguageSelector from '../layout/LanguageSelector';
import { log } from '../../util/Logger';

const cookieConsentKey = 'cookieConsent';

type StoredConsent = {
    essential: boolean,
    google_analytics: boolean
}

export const loadConsent = function (): StoredConsent {
    const consent = localStorage.getItem(cookieConsentKey);
    if (consent == null) {
        return { essential: true, google_analytics: false };
    } else {
        return JSON.parse(consent);
    }
}

interface ConsentWidgetProps {
    onConsent: (storedConsent: StoredConsent) => void
    showLanguageSelector: boolean
}

const ConsentWidget: React.FC<ConsentWidgetProps> = ({
    showLanguageSelector,
    onConsent
}) => {
    const { enableAnalytics, disableAnalytics } = useAnalytics();
    const [showSettings, setShowSettings] = useState(false);
    const [essentialCookies, setEssentialCookies] = useState(true);
    const [googleAnalyticsCookies, setGoogleAnalyticsCookies] = useState(true);
    const { t } = CommonTranslation();

    const handleConsent = (googleAnalytics: boolean) => {
        const consent: StoredConsent = { essential: essentialCookies, google_analytics: googleAnalytics };
        log("Saving consent:", consent);
        localStorage.setItem(cookieConsentKey, JSON.stringify(consent));
        setGoogleAnalyticsCookies(googleAnalytics);
        if (consent.google_analytics) {
            enableAnalytics();
        } else {
            disableAnalytics();
        }
        onConsent(consent);
    };

    return (
        <div>
            {showSettings ? (
                <div className="consent-settings">
                    <h2>{t(Translations.CookieConsentPopup.preferencesTitle)}</h2>
                    <Form>
                        <Form.Group>
                            <Form.Label>{t(Translations.CookieConsentPopup.essentialCookies)}</Form.Label>
                            <Form.Check
                                type="switch"
                                id="essential-cookies-switch"
                                checked={essentialCookies}
                                onChange={(e) => setEssentialCookies(e.target.checked)}
                                disabled
                                className="custom-switch"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t(Translations.CookieConsentPopup.analyticsCookies)}</Form.Label>
                            <Form.Check
                                type="switch"
                                id="analytics-cookies-switch"
                                checked={googleAnalyticsCookies}
                                onChange={(e) => setGoogleAnalyticsCookies(e.target.checked)}
                                className="custom-switch"
                            />
                        </Form.Group>
                    </Form>
                    <div className="mt-3">
                        <Button className="me-2" variant="secondary" onClick={() => setShowSettings(false)}>
                            {t(Translations.FormField.back)}
                        </Button>
                        <Button variant="primary" onClick={() => handleConsent(googleAnalyticsCookies)}>
                            {t(Translations.CookieConsentPopup.savePreferences)}
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="consent-summary">
                    <h2>{t(Translations.CookieConsentPopup.mainTitle)}</h2>
                    <div className="consent-summary-text">
                        <p>{t(Translations.CookieConsentPopup.mainBody)}</p>
                    </div>
                    <Button className="me-2" variant="primary" onClick={() => handleConsent(true)}>
                        {t(Translations.CookieConsentPopup.allowAll)}
                    </Button>
                    <Button className="me-2" variant="secondary" onClick={() => handleConsent(false)}>
                        {t(Translations.CookieConsentPopup.rejectAll)}
                    </Button>
                    <Button variant="secondary" onClick={() => setShowSettings(true)}>
                        {t(Translations.CookieConsentPopup.preferences)}
                    </Button>
                    {showLanguageSelector &&
                        <div className="mt-3">
                            <LanguageSelector />
                        </div>
                    }
                </div>
            )}
        </div>
    );
};

export default ConsentWidget;
