export const paths = {
  index: "/",
  privacyPolicy: "/privacy-policy",
  cookiePolicy: "/cookie-policy",
  faq: "/faq",
  contact: "/contact",
  selectPlan: "/plans",
  register: "/register",
  registrationError: "/registration-error",
  checkEmail: "/check-email",
  accountVerify: "/account/verify",
  login: "/login",
  magicCode: "/magic_code",
  oauthCallback: "/oauth/callback",
  dashboard: "/dashboard",
  payment: "/payment",
  item: "/item",
  itemCode: "/code",
  chat: "/chat",
};
