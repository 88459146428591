import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./I18N";
import { useAuth } from "./util/AuthContext";
import { paths } from "./Paths";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { AnalyticsProvider } from "./util/GoogleAnalyticsContext";
import ConsentPopup from "./components/consent/ConsentPopup";
import IndexPage from "./pages/IndexPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import FAQPage from "./pages/FAQPage";
import AccountRegistrationPage from "./pages/account/AccountRegistrationPage";
import UserRegistrationErrorPage from "./pages/account/UserRegistrationErrorPage";
import CheckEmailPage from "./pages/account/CheckEmailPage";
import AccountVerifyPage from "./pages/account/AccountVerifyPage";
import LoginPage from "./pages/account/LoginPage";
import MagicCodeVerifyPage from "./pages/account/MagicCodeVerifyPage";
import OAuthCallbackPage from "./pages/account/OAuthCallbackPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import ItemDetailPage from "./pages/dashboard/ItemDetailPage";
import EnterItemCodePage from "./pages/chat/EnterItemCodePage";
import ChatPage from "./pages/chat/ChatPage";
import ContactPage from "./pages/ContactPage";
import SelectPlanPage from "./pages/plan/SelectPlanPage";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import NotFoundPage from "./pages/NotFoundPage";
import PaymentPage from "./pages/PaymentPage";

function App() {
  const [isRegistered, setIsRegistered] = useState(false);
  const { updateAuthStatus } = useAuth();

  const handleLoginSuccess = () => {
    updateAuthStatus();
  };

  return (
    <AnalyticsProvider>
      <Router>
        <Routes>
          <Route path={paths.index} element={<IndexPage />} />
          <Route path={paths.privacyPolicy} element={<PrivacyPolicyPage />} />
          <Route path={paths.cookiePolicy} element={<CookiePolicyPage />} />
          <Route path={paths.faq} element={<FAQPage />} />
          <Route path={paths.contact} element={<ContactPage />} />
          <Route path={paths.selectPlan} element={<SelectPlanPage />} />
          <Route
            path={paths.register}
            element={
              <AccountRegistrationPage setIsRegistered={setIsRegistered} />
            }
          />
          <Route
            path={paths.registrationError}
            element={
              isRegistered ? (
                <UserRegistrationErrorPage />
              ) : (
                <Navigate to={paths.index} />
              )
            }
          />
          <Route
            path={paths.checkEmail}
            element={
              isRegistered ? <CheckEmailPage /> : <Navigate to={paths.index} />
            }
          />
          <Route path={paths.accountVerify} element={<AccountVerifyPage />} />
          <Route
            path={paths.login}
            element={<LoginPage onLoginSuccess={handleLoginSuccess} />}
          />
          <Route path={paths.magicCode} element={<MagicCodeVerifyPage />} />
          <Route path={paths.oauthCallback} element={<OAuthCallbackPage />} />
          <Route path={paths.dashboard} element={<DashboardPage />} />
          <Route path={paths.payment} element={<PaymentPage />} />
          <Route path={`${paths.item}/:itemId`} element={<ItemDetailPage />} />
          <Route path={paths.itemCode} element={<EnterItemCodePage />} />
          <Route path={`${paths.chat}/:itemCode`} element={<ChatPage />} />
          <Route path={`${paths.chat}/:itemCode`} element={<ChatPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <ConsentPopup />
      </Router>
    </AnalyticsProvider>
  );
}

export default App;
