import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../../util/Endpoints";
import { APIResponse } from "../../../../util/APIResponse";
import HTTPMethod from "../../../../util/HTTPMethod";
import { Requestor } from "../../../../util/Requestor";

export interface Params {
  token: string;
}

export interface Input extends RequestInput {
  url: {
    activation: {
      token: string;
    };
  };
}

export interface Output {}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/account/verify",
  method: HTTPMethod.GET,
  prepareInput: ({ token }: Params): Input => ({
    url: {
      activation: {
        token,
      },
    },
  }),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
