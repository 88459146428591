import ReactGA from 'react-ga';

// Extend the window interface to include the GA disable property
declare global {
    interface Window {
        [key: string]: any;
    }
}

let isInitialized = false;

const initializeAnalytics = (trackingId: string) => {
    ReactGA.initialize(trackingId);
    isInitialized = true;
};

const disableAnalytics = (trackingId: string) => {
    window['ga-disable-' + trackingId] = true;
    isInitialized = false;
};

const logPageView = () => {
    if (!isInitialized) {
        return;
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
};

const logError = (error: string, ...optionalParams: any[]) => {
    if (!isInitialized) {
        return;
    }
    ReactGA.event({
        category: 'Error',
        action: error,
        label: optionalParams.join(', '),
        nonInteraction: true,
    });
};

const logWarning = (warning: string, ...optionalParams: any[]) => {
    if (!isInitialized) {
        return;
    }
    ReactGA.event({
        category: 'Warning',
        action: warning,
        label: optionalParams.join(', '),
        nonInteraction: true,
    });
};

const webVitals = ({ name, delta, id }: { name: string, delta: number, id: string }) => {
    if (!isInitialized) {
        return;
    }
    ReactGA.event({
        category: 'Web Vitals',
        action: name,
        label: id,
        nonInteraction: true,
        value: Math.round(name === 'CLS' ? delta * 1000 : delta),
    });
};

export { initializeAnalytics, logPageView, disableAnalytics, webVitals, logError, logWarning };
