import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../util/AuthContext";
import { useNavigate } from "react-router-dom";
import { Send, Output } from "../../endpoints/subscription/update/Endpoint";
import { error as logError, log } from "../../util/Logger";
import { paths } from "../../Paths";
import {
  planBasic,
  planDetails,
  planEnterprise,
  planFree,
  planPro,
} from "./Plans";
import { t } from "i18next";
import { plansKey, Translations } from "../../locales/translations";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import envVariables from "../../EnvVars";

export const usePlans = () => {
  const navigate = useNavigate();
  const { account, updateAccountInfo } = useAuth();
  const [currentPlan, setCurrentPlan] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const useStripeLazyLoad = () => {
    const [stripe, setStripe] = useState<Stripe | null>(null);

    const loadStripeLibrary = useCallback(async () => {
      if (!stripe) {
        const stripeLoaded = await loadStripe(
          envVariables.STRIPE_PUBLISHABLE_KEY
        );
        setStripe(stripeLoaded);
        return stripeLoaded; // Return the loaded Stripe instance
      }
      return stripe;
    }, [stripe]);

    return { stripe, loadStripeLibrary };
  };

  const { loadStripeLibrary } = useStripeLazyLoad();

  const redirectToCheckout = async (sessionID: string) => {
    const stripeLoaded = await loadStripeLibrary();
    if (!stripeLoaded) {
      logError("Stripe.js is not loaded");
      return;
    }
    const { error } = await stripeLoaded.redirectToCheckout({
      sessionId: sessionID,
    });
    if (error) {
      logError("Error:", error);
    }
  };

  const displayPlans = [
    {
      id: planFree,
      onSelect: () => sendPlanUpdate(planFree),
      name: t(Translations.Plans.planFree),
      price: t(Translations.Plans.priceFree),
      features: t(`${plansKey}.featuresFree`, {
        returnObjects: true,
        items: planDetails[planFree].featureMaxItemCount,
      }) as Array<string>,
    },
    {
      id: planBasic,
      onSelect: () => sendPlanUpdate(planBasic),
      name: t(Translations.Plans.planBasic),
      price: t(Translations.Plans.priceMonthOrYear, {
        priceMonth: `${planDetails[planBasic].invoicePerMonth}€`,
      }),
      features: t(`${plansKey}.featuresBasic`, {
        returnObjects: true,
        items: planDetails[planBasic].featureMaxItemCount,
      }) as Array<string>,
    },
    {
      id: planPro,
      onSelect: () => sendPlanUpdate(planPro),
      name: t(Translations.Plans.planPro),
      price: t(Translations.Plans.priceMonthOrYear, {
        priceMonth: `${planDetails[planPro].invoicePerMonth}€`,
      }),
      features: t(`${plansKey}.featuresPro`, {
        returnObjects: true,
        items: planDetails[planPro].featureMaxItemCount,
      }) as Array<string>,
    },
    {
      id: planEnterprise,
      onSelect: () => handleSelectEnterprisePlan(),
      name: t(Translations.Plans.planEnterprise),
      price: t(Translations.Plans.priceCustom),
      features: t(`${plansKey}.featuresEnterprise`, {
        returnObjects: true,
        items: planDetails[planEnterprise].featureMaxItemCount,
      }) as Array<string>,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    if (account?.account && account.account.plan_name) {
      setCurrentPlan(account.account.plan_name);
    }
    setIsLoading(false);
  }, [account]);

  const sendPlanUpdate = async (
    planID: string,
    handleFunction?: (
      billingPortalURL: string | undefined,
      sessionID: string | undefined
    ) => void
  ) => {
    setIsLoading(true);

    log(`Sending select plan: ${planID}`);
    const apiResponse = await Send({
      subscription: {
        name: planID,
      },
    });
    log("Select plan update response:", apiResponse);

    setIsLoading(false);

    if (apiResponse.status === 200) {
      log("Select plan success");
      if (apiResponse.payload == null) {
        logError("No payload from select plan");
        return;
      }

      if (handleFunction) {
        handleFunction(
          apiResponse.payload.billing_portal_url,
          apiResponse.payload.session_id
        );
      } else {
        handleSelectPlanUpdateResponse(
          apiResponse.payload.billing_portal_url,
          apiResponse.payload.session_id
        );
      }
    } else {
      logError("Select plan failed");
    }
  };

  const handleSelectPlanUpdateResponse = async (
    billingPortalURL: string | undefined,
    sessionID: string | undefined
  ) => {
    if (billingPortalURL) {
      log("Billing portal URL from select plan:", billingPortalURL);
      window.location.href = billingPortalURL;
    } else if (!sessionID) {
      log("No session ID from select plan");
      await updateAccountInfo();
    } else {
      log("Session ID from select plan:", sessionID);
      redirectToCheckout(sessionID);
    }
  };

  const handleSelectEnterprisePlan = () => {
    log("Selected custom plan");
    navigate(paths.contact);
  };

  return {
    currentPlan,
    isLoading,
    setIsLoading,
    sendPlanUpdate,
    handleSelectEnterprisePlan,
    displayPlans,
  };
};
