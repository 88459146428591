import React from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../../Paths";
import { Item } from "../../endpoints/item/get/Endpoint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import "./RegisteredItemsCards.css";
import { CommonTranslation, Translations } from "../../I18N";

const RegisteredItemsCards: React.FC<{
  items: Item[];
  highlightedItemId: string | null;
}> = ({ items, highlightedItemId }) => {
  const navigate = useNavigate();
  const { t } = CommonTranslation();

  const sortedItems = [...items].sort((a, b) => b.created - a.created);

  return (
    <div className="mb-4">
      {items.length === 0 && (
        <p>{t(Translations.RegisteredItemsList.noItems)}</p>
      )}
      <div className="row">
        {sortedItems.reverse().map((item) => (
          <div key={item.id} className="col-md-6 col-lg-4 mb-4">
            <div
              className={`card item-list-card ${
                highlightedItemId === item.id ? "highlighted" : ""
              }`}
            >
              <div className="card-body item-list-card-body d-flex flex-column">
                <h5 className="card-title item-list-card-title d-flex align-items-center">
                  {item.name}
                  {item.chat_is_unread ? (
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="ms-2 text-danger"
                      title={t(Translations.RegisteredItemsList.unreadMessages)}
                      aria-label={t(
                        Translations.RegisteredItemsList.unreadMessages
                      )}
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <p className="card-text item-list-card-text">
                  {item.description}
                </p>
                <p className="card-text item-list-card-date">
                  {t(Translations.Generic.created)}:{" "}
                  {new Date(item.created / 1_000_000).toLocaleDateString()}
                </p>
                <button
                  className="btn btn-primary mt-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${paths.item}/${item.id}`);
                  }}
                >
                  {t(Translations.RegisteredItemsList.viewDetails)}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RegisteredItemsCards;
