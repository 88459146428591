import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../../components/layout/Navbar';
import Footer from '../../components/layout/Footer';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../util/AuthContext';
import { Send, Output } from '../../endpoints/account/magic_link_session/verify_long/Endpoint';
import { APIResponse, } from '../../util/APIResponse';
import { paths } from '../../Paths';
import { CommonTranslation, Translations } from '../../I18N';
import { error, log } from '../../util/Logger';

const MagicCodeVerifyPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const navigate = useNavigate();
    const { updateAuthStatus } = useAuth();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [verifySuccess, setVerifySuccess] = useState<boolean>(false);
    const mounted = useRef(false);
    const { t } = CommonTranslation();

    useEffect(() => {
        if (!mounted.current && code) {
            verifyMagicCode(code);
            mounted.current = true;
        }
    }, [code]);

    const verifyMagicCode = async (code: string) => {
        log("Verifying magic code");

        const apiResponse = await Send(
            {
                code: code,
            },
        );

        log("Magic code verification response:", apiResponse);
        await (handleVerifyMagicCode(apiResponse));
    };

    const handleVerifyMagicCode = async (
        apiResponse: APIResponse<Output>,
    ) => {
        if (apiResponse.status === 200) {
            log("Magic code verification success");
            setVerifySuccess(await updateAuthStatus());
            navigate(paths.dashboard);

        } else {
            error("Magic code verification failed");
            setErrorMessage(apiResponse.errorText);
        }
    };

    return (
        <div className="full-height">
            <Navbar />
            <div className="container mt-5 flex-grow-1">
                <h1>Magic Code Verification</h1>
                {code ? (
                    errorMessage ? (
                        <div>
                            <p>{t(Translations.MagicCodeVerifyPage.verifyingMagicCode)}</p>
                            <p>{t(Translations.Error.errorMessage)}: {errorMessage}</p>
                            <p>{t(Translations.MagicCodeVerifyPage.errorHelp)}</p>
                        </div>
                    ) : verifySuccess ? (
                        <p>{t(Translations.MagicCodeVerifyPage.verifyingMagicCode)} {t(Translations.MagicCodeVerifyPage.verifyMagicCodeSuccess)}</p>
                    ) : (
                        <p>{t(Translations.MagicCodeVerifyPage.verifyingMagicCode)}</p>
                    )
                ) : (
                    <p>{t(Translations.Error.error)}: {t(Translations.MagicCodeVerifyPage.noMagicCodeProvided)}</p>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default MagicCodeVerifyPage;
