import { Send, Output } from '../endpoints/account/account/get/Endpoint';
import { log } from './Logger';

export const getAccount = async (): Promise<Output | null> => {
    log("Getting account");
    const apiResponse = await Send({})
    log("Get account response:", apiResponse);

    if (apiResponse.payload) {
        log("Account found:", apiResponse.payload);
        return apiResponse.payload;
    } else {
        log("No account found");
        return null;
    }
};
