import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../../util/Endpoints";
import { APIResponse } from "../../../../util/APIResponse";
import HTTPMethod from "../../../../util/HTTPMethod";
import { Requestor } from "../../../../util/Requestor";

export interface Params {}
export interface Input extends RequestInput {}

export interface Output {
  account: {
    plan_name: string;
    feature_max_items: number;
    feature_item_public_info: boolean;
    preference_send_email_on_chat_message: boolean;
  };
}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/account",
  method: HTTPMethod.GET,
  prepareInput: ({}: Params): Input => ({}),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
