import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../util/Endpoints";
import { APIResponse } from "../../../util/APIResponse";
import HTTPMethod from "../../../util/HTTPMethod";
import { Requestor } from "../../../util/Requestor";
import { Selectors } from "../Selectors";
import { Sort } from "../../../util/Sort";
import { Page } from "../../../util/Page";

export const limitMax = 100;

export interface Params {
  selectors: Selectors;
  sorts?: Sort[];
  page?: Page;
  get_count?: boolean;
}

export interface Input extends RequestInput {
  url: Params;
}

export interface Output {
  items: Item[];
  count: number;
}

export interface Item {
  id: string;
  created: number;
  updated: number;
  name: string;
  description: string;
  public_info: string;
  item_code_created: number;
  item_code: string;
  chat_is_unread: boolean;
}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/item",
  method: HTTPMethod.GET,
  prepareInput: (params: Params): Input => ({
    url: params,
  }),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
