import NavBar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { paths } from "../Paths";
import { Translations } from "../locales/translations";
import { t } from "i18next";

const cancelReason = "cancel";

const PaymentPage: React.FC = () => {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const success = urlParams.get("success")
    ? urlParams.get("success") === "true"
    : false;
  const reason = urlParams.get("reason");

  useEffect(() => {
    let canRedirect = true;
    if (!success) {
      if (reason !== cancelReason) {
        canRedirect = false;
      }
    }
    if (canRedirect) {
      navigate(paths.selectPlan);
    }
  }, []);

  return (
    <div className="full-height">
      <NavBar />
      <div className="container flex-grow-1"></div>
      {!success ? `${t(Translations.Error.error)}: ${reason}` : ""}
      <Footer />
    </div>
  );
};

export default PaymentPage;
