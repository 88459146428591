import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../../components/layout/Navbar';
import Footer from '../../components/layout/Footer';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../util/AuthContext';
import { Send, Output } from '../../endpoints/account/account/verify/Endpoint';
import { APIResponse, } from '../../util/APIResponse';
import { paths } from '../../Paths';
import { CommonTranslation, Translations } from '../../I18N';
import { error, log } from '../../util/Logger';

const userNotAuthenticated: string = "User not authenticated.";

const AccountVerifyPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();
    const { updateAuthStatus } = useAuth();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [verifySuccess, setVerifySuccess] = useState<boolean>(false);
    const { t } = CommonTranslation();
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current && token) {
            verifyAccount(token);
            mounted.current = true;
        }
    }, [token]);

    const verifyAccount = async (token: string) => {
        log("Verifying account");

        const apiResponse = await Send({
            token: token,
        },);

        log("Account verification response:", apiResponse);
        await (handleVerifyAccount(apiResponse));
    };

    const handleVerifyAccount = async (
        apiResponse: APIResponse<Output>,
    ) => {
        const startTime = Date.now();

        if (apiResponse.status === 200) {
            log("Account verification success");
            const isAuthenticated = await updateAuthStatus();
            setVerifySuccess(isAuthenticated);

            if (!isAuthenticated) {
                setErrorMessage(userNotAuthenticated);
            } else {
                navigate(paths.dashboard);
            }
        } else {
            error("Account verification failed");
            setErrorMessage(apiResponse.errorText);
        }
    };

    return (
        <div className="full-height">
            <Navbar />
            <div className="container mt-5 flex-grow-1">
                <h1>{t(Translations.AccountVerifyPage.title)}</h1>
                {token ? (
                    errorMessage ? (
                        <div>
                            <p>{t(Translations.AccountVerifyPage.verificationFailed)}</p>
                            <p>{t(Translations.Error.errorMessage)}: {errorMessage}</p>
                            <p>{t(Translations.AccountVerifyPage.errorHelp)}</p>
                        </div>
                    ) : verifySuccess ? (
                        <p>{t(Translations.AccountVerifyPage.verifyingAccount)} {t(Translations.AccountVerifyPage.verifyAccountSuccess)} Success! Taking you to another page...</p>
                    ) : (
                        <p>{t(Translations.AccountVerifyPage.verifyingAccount)}</p>
                    )
                ) : (
                    <p>Error: No verification token provided.</p>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default AccountVerifyPage;
