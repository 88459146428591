import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../util/AuthContext";
import { paths } from "../../Paths";
import { CommonTranslation, Translations } from "../../I18N";
import { Container, Nav, Navbar as NavbarBootstrap } from "react-bootstrap";
import { planFree } from "../../util/plans/Plans";
import envVariables from "../../EnvVars";

const Navbar = () => {
  const { isLoggedIn, logout, account } = useAuth();
  const navigate = useNavigate();
  const { t } = CommonTranslation();

  const handleLogout = async () => {
    await logout();
    navigate(paths.index);
  };

  return (
    <NavbarBootstrap bg="primary" variant="dark" expand="lg">
      <Container>
        <NavbarBootstrap.Brand
          as={Link}
          to={paths.index}
          className="brand-text"
        >
          <img
            src="/logo192.png"
            alt="Logo"
            style={{ width: "30px", height: "30px" }}
          />
          HyperItem.com
        </NavbarBootstrap.Brand>
        <NavbarBootstrap.Toggle aria-controls="basic-navbar-nav" />
        <NavbarBootstrap.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {/*<Nav.Link as={Link} to={paths.index}>{t(Translations.Navigation.home)}</Nav.Link> */}
            <Nav.Link as={Link} to={paths.itemCode}>
              {t(Translations.Navigation.reportItem)}
            </Nav.Link>
            {isLoggedIn ? (
              <>
                {envVariables.SHOW_PLANS &&
                  account?.account.plan_name === planFree && (
                    <Nav.Link as={Link} to={paths.selectPlan}>
                      {t(Translations.Navigation.selectPlan)}
                    </Nav.Link>
                  )}
                <Nav.Link as={Link} to={paths.dashboard}>
                  {t(Translations.Navigation.dashboard)}
                </Nav.Link>
                <Nav.Link onClick={handleLogout} style={{ cursor: "pointer" }}>
                  {t(Translations.Navigation.logout)}
                </Nav.Link>
              </>
            ) : (
              <>
                {envVariables.SHOW_PLANS ? (
                  <Nav.Link as={Link} to={paths.selectPlan}>
                    {t(Translations.Navigation.selectPlan)}
                  </Nav.Link>
                ) : null}
                <Nav.Link as={Link} to={paths.register}>
                  {t(Translations.Navigation.register)}
                </Nav.Link>
                <Nav.Link as={Link} to={paths.login}>
                  {t(Translations.Navigation.login)}
                </Nav.Link>
              </>
            )}
          </Nav>
        </NavbarBootstrap.Collapse>
      </Container>
    </NavbarBootstrap>
  );
};

export default Navbar;
