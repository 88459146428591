import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faCopy,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Navbar from "../../components/layout/Navbar";
import ChatSection from "../../components/dashboard/ChatSection";
import Footer from "../../components/layout/Footer";
import { paths } from "../../Paths";
import { Send as GetItemSend, Item } from "../../endpoints/item/get/Endpoint";
import { Send as UpdateItemSend } from "../../endpoints/item/update/Endpoint";
import { Send as DeleteItemSend } from "../../endpoints/item/delete/Endpoint";
import { Send as RegenerateItemCodeSend } from "../../endpoints/item_code/create/Endpoint";
import { equal } from "../../util/Predicates";
import "./ItemDetailPage.css";
import { CommonTranslation, Translations } from "../../I18N";
import envVariables from "../../EnvVars";
import { error, log } from "../../util/Logger";
import { useAuth } from "../../util/AuthContext";

interface ItemDetailPageProps {}

const ItemDetailPage: React.FC<ItemDetailPageProps> = ({}) => {
  const { itemId } = useParams<{ itemId: string }>();
  const navigate = useNavigate();
  const [item, setItem] = useState<Item | null>(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [publicInfo, setPublicInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [showCode, setShowCode] = useState(false);
  const [copyFeedback, setCopyItemCodeFeedback] = useState(false);
  const [chatURLCopyFeedback, setCopyChatURLFeedback] = useState(false);
  const mounted = useRef(false);
  const { t } = CommonTranslation();
  const [highlight, setHighlight] = useState(false);
  const [isSendingDeleteItem, setIsSendingDeleteItem] = useState(false);
  const [isSendingEditItem, setIsSendingEditItem] = useState(false);
  const [isEditingItem, setIsEditingItem] = useState(false);
  const { account } = useAuth();

  useEffect(() => {
    if (!mounted.current && itemId) {
      getItemDetails(itemId);
      mounted.current = true;
    }
  }, [itemId]);

  const handleBack = () => {
    navigate(paths.dashboard);
  };

  const toggleCodeVisibility = () => {
    setShowCode(!showCode);
  };

  const copyToClipboard = (
    code: string,
    setFeedback: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    navigator.clipboard.writeText(code);
    setFeedback(true);
    setTimeout(() => {
      setFeedback(false);
    }, 2000);
  };

  const getItemDetails = async (id: string) => {
    log("Getting item:", id);
    const apiResponse = await GetItemSend({
      selectors: {
        id: {
          predicate: equal,
          value: id,
        },
      },
    });
    log("Get item response:", apiResponse);

    if (
      apiResponse.status === 200 &&
      apiResponse.payload?.items?.length === 1
    ) {
      log("Get item success");
      const fetchedItem = apiResponse.payload.items[0];
      setItem(fetchedItem);
      setName(fetchedItem.name);
      setDescription(fetchedItem.description);
      setPublicInfo(fetchedItem.public_info);
    } else {
      error("Get item failed");
    }

    setLoading(false);
  };

  const handleEdit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!itemId || !item) return;

    log("Editing item:", item);
    setIsEditingItem(true);
    setIsSendingEditItem(true);

    const apiResponse = await UpdateItemSend({
      selectors: {
        id: {
          predicate: equal,
          value: itemId,
        },
      },
      updates: {
        name: {
          value: name,
        },
        description: {
          value: description,
        },
        public_info: {
          value: publicInfo,
        },
      },
    });

    if (apiResponse.status === 200 && apiResponse.payload?.count === 1) {
      log("Edit item success");
      getItemDetails(itemId);
    } else {
      error("Edit item failed");
    }

    setIsEditingItem(false);
    setIsSendingEditItem(false);
  };

  const handleDelete = async () => {
    if (!itemId) return;

    const confirmDelete = window.confirm(
      t(Translations.ItemDetailPage.confirmDelete)
    );

    if (confirmDelete) {
      log("Deleting item:", itemId);
      setIsSendingDeleteItem(true);

      const apiResponse = await DeleteItemSend({
        selectors: {
          id: {
            predicate: equal,
            value: itemId,
          },
        },
      });
      log("Delete item response:", apiResponse);

      if (apiResponse.status === 200) {
        log("Delete item success");
        navigate(paths.dashboard);
      } else {
        error("Delete item failed");
      }

      setIsSendingDeleteItem(false);
    }
  };

  const handleRegenerateCode = async () => {
    if (!item) return;

    const confirmRegenerate = window.confirm(
      t(Translations.ItemDetailPage.confirmItemCodeRegeneration)
    );
    if (confirmRegenerate) {
      log("Regenerating item code for:", item.item_code);
      const apiResponse = await RegenerateItemCodeSend({
        item_code: {
          item_code: item.item_code,
        },
      });

      log("Regenerate item code response:", apiResponse);
      if (apiResponse.status === 200 && apiResponse.payload?.item_code) {
        log("Regenerate item code success");
        setItem({
          ...item,
          item_code: apiResponse.payload.item_code.code,
        });
        log("Updated item:", item);
        setHighlight(true);
        setTimeout(() => {
          setHighlight(false);
        }, 3000);
      } else {
        error("Regenerate item code failed");
      }
    }
  };

  const getItemChatURL = () => {
    if (!item) return "";
    const visibleCode = showCode
      ? item.item_code
      : "*".repeat(item.item_code.length);
    return `${envVariables.FRONTEND_HOST}${paths.chat}/${visibleCode}`;
  };

  const getItemChatURLPlain = () => {
    if (!item) return "";
    return `${envVariables.FRONTEND_HOST}${paths.chat}/${item.item_code}`;
  };

  return (
    <div className="full-height">
      <Navbar />
      <div className="container mt-5 flex-grow-1">
        <button onClick={handleBack} className="btn btn-light mb-2">
          <FontAwesomeIcon icon={faArrowLeft} />
          {t(Translations.ItemDetailPage.backToDashboard)}
        </button>
        <h2>{t(Translations.ItemDetailPage.itemDetails)}</h2>

        {loading ? (
          <div>{t(Translations.Generic.loading)}</div>
        ) : item ? (
          <div className="mb-4">
            {isEditingItem ? (
              <form onSubmit={handleEdit}>
                <div className="mb-3">
                  <label htmlFor="itemName" className="form-label">
                    {t(Translations.ItemDetail.itemName)}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="itemName"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="itemDescription" className="form-label">
                    {t(Translations.ItemDetail.itemDescription)}
                  </label>
                  <textarea
                    className="form-control"
                    id="itemDescription"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </div>
                {account?.account.feature_item_public_info ? (
                  <div className="mb-3">
                    <label htmlFor="itemPublicInfo" className="form-label">
                      {t(Translations.ItemDetail.itemPublicInfo)}
                    </label>
                    <textarea
                      className="form-control"
                      id="itemPublicInfo"
                      value={publicInfo}
                      onChange={(e) => setPublicInfo(e.target.value)}
                      required
                    />
                  </div>
                ) : null}
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSendingEditItem}
                >
                  {isSendingEditItem
                    ? t(Translations.Generic.sending)
                    : t(Translations.FormField.save)}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary ms-2"
                  onClick={() => setIsEditingItem(false)}
                >
                  {t(Translations.FormField.cancel)}
                </button>
              </form>
            ) : (
              <div>
                <h4>{item.name}</h4>
                {t(Translations.ItemDetail.itemDescription)}
                {item.description ? (
                  <p>{item.description}</p>
                ) : (
                  <p className="text-muted">
                    {t(Translations.ItemDetail.noDescriptionAvailable)}
                  </p>
                )}
                {account?.account.feature_item_public_info ? (
                  <div>
                    {t(Translations.ItemDetail.itemPublicInfo)}
                    {item.public_info ? (
                      <p>{item.public_info}</p>
                    ) : (
                      <p className="text-muted">
                        {t(Translations.ItemDetail.noPublicInfoAvailable)}
                      </p>
                    )}
                  </div>
                ) : null}
                <p className="text-muted">
                  {t(Translations.Generic.created)}:{" "}
                  {new Date(item.created / 1_000_000).toLocaleString(
                    undefined,
                    {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    }
                  )}
                  <br></br>
                  {t(Translations.Generic.updated)}:{" "}
                  {new Date(item.updated / 1_000_000).toLocaleString(
                    undefined,
                    {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    }
                  )}{" "}
                </p>
                <div className="d-flex align-items-center mb-2">
                  <button
                    className="btn btn-secondary"
                    onClick={() => setIsEditingItem(true)}
                  >
                    {t(Translations.FormField.edit)}
                  </button>
                  <button
                    className="btn btn-danger ms-2"
                    onClick={handleDelete}
                    disabled={isSendingDeleteItem}
                  >
                    {isSendingDeleteItem
                      ? t(Translations.Generic.sending)
                      : t(Translations.FormField.delete)}
                  </button>
                </div>
                <hr></hr>
                <h3>{t(Translations.ItemDetail.itemCode)}</h3>
                <p>{t(Translations.ItemDetail.itemCodeDescription)}</p>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0">{t(Translations.ItemDetail.itemCode)}:</p>
                  <span
                    className={`code-text text-uppercase ms-2 ${
                      highlight ? "highlight" : ""
                    }`}
                  >
                    {showCode
                      ? item.item_code
                      : "*".repeat(item.item_code.length)}
                  </span>
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="icon-button ms-2"
                    title={t(Translations.ItemDetail.copyItemCode)}
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(item.item_code, setCopyItemCodeFeedback);
                    }}
                  />
                  {copyFeedback && (
                    <span className="ms-2 text-success">
                      {t(Translations.ItemDetail.copiedToClipboard)}
                    </span>
                  )}
                </div>
                <div className="d-flex align-items-center mb-2 flex-wrap">
                  <div className="mb-0">
                    {t(Translations.ItemDetail.itemChatURL)}:<br></br>
                    {getItemChatURL()}
                    <FontAwesomeIcon
                      icon={faCopy}
                      className="icon-button ms-2"
                      title={t(Translations.ItemDetail.copyChatURL)}
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipboard(
                          getItemChatURLPlain(),
                          setCopyChatURLFeedback
                        );
                      }}
                    />
                    {chatURLCopyFeedback && (
                      <span className="ms-2 text-success">
                        {t(Translations.ItemDetail.copiedToClipboard)}
                      </span>
                    )}
                  </div>
                </div>
                <button
                  className="btn btn-primary mb-2 me-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleCodeVisibility();
                  }}
                >
                  <FontAwesomeIcon
                    icon={showCode ? faEyeSlash : faEye}
                    className="icon-button ms-0"
                  />
                  {showCode
                    ? t(Translations.ItemDetail.hideItemCode)
                    : t(Translations.ItemDetail.showItemCode)}
                </button>
                <button
                  className="btn btn-warning mb-2 me-2"
                  onClick={handleRegenerateCode}
                >
                  {t(Translations.ItemDetailPage.regenerateItemCode)}
                </button>
                <hr />
                <h3>{t(Translations.ItemDetail.itemChat)}</h3>
                <p>{t(Translations.ItemDetail.itemChatTitle)}</p>
                {item && (
                  <ChatSection
                    key={item.item_code}
                    itemCode={item.item_code}
                    useReCaptcha={false}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <div>{t(Translations.ItemDetail.itemNotFound)}</div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ItemDetailPage;
