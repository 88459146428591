import EnvVars from '../EnvVars';
import { IRequestor } from './IRequestor';
import { APIResponse } from './APIResponse';

export interface RequestInput {
    url?: any;
    body?: any;
    headers?: any;
}

export interface EndpointDefinition<InputParameters, Input> {
    path: string;
    method: string;
    prepareInput: (params: InputParameters) => Input;
}

export interface Endpoint<RequestInput> {
    url: string;
    method: string;
    input: RequestInput;
}

export const GetFullURL = (
    endpointDefinition: EndpointDefinition<any, any>,
) => `${EnvVars.BACKEND_HOST}${endpointDefinition.path}`;


export function RequestEndpoint<InputParameters, Input, Output>(
    endpointDefinition: EndpointDefinition<InputParameters, Input>,
    params: InputParameters,
    requestor: IRequestor<Input, Output>,
    credentials: RequestCredentials,
): Promise<APIResponse<Output>> {
    const endpoint: Endpoint<Input> = {
        url: GetFullURL(endpointDefinition),
        method: endpointDefinition.method,
        input: endpointDefinition.prepareInput(params),
    };

    return requestor.SendRequest(endpoint, credentials);
}

export function PrepareEndpoint<InputParameters, Input>(
    endpointDefinition: EndpointDefinition<InputParameters, Input>,
    params: InputParameters,
    prepareInput: (params: InputParameters) => Input
): Endpoint<Input> {
    const input = prepareInput(params);
    const url = GetFullURL(endpointDefinition);

    return {
        url,
        method: endpointDefinition.method,
        input
    };
}
