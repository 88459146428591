const throwMissing = (key: string) => {
  throw new Error(`Environment variable not found: ${key}`);
};
const warnMissing = (key: string) => {
  console.warn(`Environment variable not found: ${key}`);
};

const backendHostEnvVar = "REACT_APP_BACKEND_HOST";
const frontendHostEnvVar = "REACT_APP_FRONTEND_HOST";
const recaptchaSiteKeyEnvVar = "REACT_APP_RECAPTCHA_SITE_KEY";
const contactFormURLENEnvVar = "REACT_APP_CONTACT_FORM_URL_EN";
const contactFormURLFIEnvVar = "REACT_APP_CONTACT_FORM_URL_FI";
const googleAnalyticsIDEnvVar = "REACT_APP_GOOGLE_ANALYTICS_ID";
const fullLoggingEnvVar = "REACT_APP_FULL_LOGGING";
const showPlansEnvVar = "REACT_APP_SHOW_PLANS";
const stripePublishableKey = "REACT_APP_STRIPE_PUBLISHABLE_KEY";

const backendHost =
  process.env[backendHostEnvVar] || throwMissing(backendHostEnvVar);
const frontendHost =
  process.env[frontendHostEnvVar] || throwMissing(frontendHostEnvVar);
const recaptchaSiteKey =
  process.env[recaptchaSiteKeyEnvVar] || throwMissing(recaptchaSiteKeyEnvVar);
const contactFormURLEN =
  process.env[contactFormURLENEnvVar] || throwMissing(contactFormURLENEnvVar);
const contactFormURLFI =
  process.env[contactFormURLFIEnvVar] || throwMissing(contactFormURLFIEnvVar);
const googleAnalyticsID =
  process.env[googleAnalyticsIDEnvVar] || throwMissing(googleAnalyticsIDEnvVar);
const fullLogging =
  process.env[fullLoggingEnvVar] || throwMissing(fullLoggingEnvVar);
const showPlans =
  process.env[showPlansEnvVar] || warnMissing(fullLoggingEnvVar);
const stripePublishableKeyEnvVar =
  process.env[stripePublishableKey] || throwMissing(stripePublishableKey);

interface EnvVariables {
  BACKEND_HOST: string;
  FRONTEND_HOST: string;
  RECAPTCHA_SITE_KEY: string;
  CONTACT_FORM_URL_EN: string;
  CONTACT_FORM_URL_FI: string;
  GOOGLE_ANALYTICS_ID: string;
  FULL_LOGGING: boolean;
  SHOW_PLANS: boolean;
  STRIPE_PUBLISHABLE_KEY: string;
}

const envVariables: EnvVariables = {
  BACKEND_HOST: backendHost,
  FRONTEND_HOST: frontendHost,
  RECAPTCHA_SITE_KEY: recaptchaSiteKey,
  CONTACT_FORM_URL_EN: contactFormURLEN,
  CONTACT_FORM_URL_FI: contactFormURLFI,
  GOOGLE_ANALYTICS_ID: googleAnalyticsID,
  FULL_LOGGING: fullLogging === "true",
  SHOW_PLANS: showPlans === "true",
  STRIPE_PUBLISHABLE_KEY: stripePublishableKeyEnvVar,
};

export default envVariables;
