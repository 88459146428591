import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../util/Endpoints";
import { APIResponse } from "../../../util/APIResponse";
import HTTPMethod from "../../../util/HTTPMethod";
import { Requestor } from "../../../util/Requestor";

export interface Params {
  name: string;
  description: string;
  public_info: string;
}

export interface Input extends RequestInput {
  body: {
    items: Params[];
  };
}

export interface Item {
  id: string;
  created: number;
  item_code: string;
  item_code_created: number;
}

export interface Output {
  items: Item[];
}

export const Endpoint: EndpointDefinition<Params[], Input> = {
  path: "/api/item",
  method: HTTPMethod.POST,
  prepareInput: (params: Params[]): Input => ({
    body: {
      items: params,
    },
  }),
};

export async function Send(params: Params[]): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params[], Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
