import { Send } from "../endpoints/account/oauth/init/Endpoint";
import { error, log } from "./Logger";

export const handleOAuthInit = async () => {
    log("Initiating OAuth");
    const apiResponse = await Send({});
    log("OAuth init response:", apiResponse);

    if (apiResponse.status === 200) {
        log("OAuth init succeeded");
        const redirectURL = apiResponse.payload?.redirect_url;
        if (!redirectURL) {
            error("Missing redirect URL");
        }
        else {
            log("Redirecting to", redirectURL);
            window.location.href = redirectURL;
        }
    } else {
        error("Get items failed");
    }
};