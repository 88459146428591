import React from 'react';
import { CommonTranslation, Translations } from '../I18N';
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';

const NotFoundPage: React.FC = () => {
    const { t } = CommonTranslation();

    return (
        <div className="full-height">
            <Navbar />
            <div className="flex-grow-1 mt-5 text-center">
                <h1>{t(Translations.NotFoundPage.title)}</h1>
                <p>{t(Translations.NotFoundPage.description)}</p>
            </div>
            <Footer />
        </div>
    );
}

export default NotFoundPage;
