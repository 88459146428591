import React from 'react';
import Navbar from '../../components/layout/Navbar';
import Footer from '../../components/layout/Footer';
import { CommonTranslation, Translations } from '../../I18N';

const CheckEmailPage: React.FC = () => {
    const { t } = CommonTranslation();

    return (
        <div className="full-height">
            <Navbar />
            <div className="container mt-5 flex-grow-1">
                <h1>{t(Translations.CheckEmailPage.registrationSuccessful)}</h1>
                <p>{t(Translations.CheckEmailPage.pleaseCheckYourEmail)}</p>
            </div>
            <Footer />
        </div>
    );
};

export default CheckEmailPage;
