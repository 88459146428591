import envVariables from "../EnvVars";
import { logError, logWarning } from "./GoogleAnalytics";

export const log = (message: any, ...optionalParams: any[]) => {
    if (process.env.NODE_ENV !== 'production' || envVariables.FULL_LOGGING) {
        console.log(message, ...optionalParams);
    }
};

export const warn = (message: any, ...optionalParams: any[]) => {
    if (process.env.NODE_ENV !== 'production' || envVariables.FULL_LOGGING) {
        console.warn(message, ...optionalParams);
        logWarning(message, ...optionalParams);
    }
}

export const error = (message: any, ...optionalParams: any[]) => {
    if (process.env.NODE_ENV !== 'production' || envVariables.FULL_LOGGING) {
        console.error(message, ...optionalParams);
        logError(message, ...optionalParams);
    }
}
