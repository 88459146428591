import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../util/Endpoints";
import { APIResponse } from "../../../util/APIResponse";
import HTTPMethod from "../../../util/HTTPMethod";
import { Requestor } from "../../../util/Requestor";
import { Selectors } from "../Selectors";

export interface Params {
  selectors?: Selectors;
}

export interface Input extends RequestInput {
  body: {
    selectors?: Selectors | undefined;
  };
}

export interface Output {
  items: Item[];
}

export interface Item {
  count: number;
}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/item",
  method: HTTPMethod.DELETE,
  prepareInput: ({ selectors }: Params): Input => ({
    body: {
      selectors,
    },
  }),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
