import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import Papa from "papaparse";
import { useTranslation } from "react-i18next";
import { Translations } from "../I18N";

const CSVUpload = <T,>({
  onNewData,
  isDisabled,
  customButtonText,
}: {
  onNewData: (items: T[]) => void;
  isDisabled: boolean;
  customButtonText?: string | undefined;
}) => {
  const { t } = useTranslation("Common");
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null); // For accessing the file input element
  const [inputKey, setInputKey] = useState(Date.now()); // A key to force re-rendering the file input

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  const handleUpload = () => {
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        delimiter: ";",
        quoteChar: '"',
        complete: (results) => {
          const data: T[] = results.data as T[];
          onNewData(data);
          // Reset the file state and re-render the input field
          setFile(null);
          setInputKey(Date.now()); // Changes the key to force re-render
        },
      });
    }
  };

  return (
    <div>
      <input
        key={inputKey}
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <Button onClick={handleUpload} disabled={!file || isDisabled}>
        {customButtonText || t(Translations.CSVUpload.upload)}
      </Button>
    </div>
  );
};

export default CSVUpload;
