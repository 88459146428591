import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../../util/Endpoints";
import { APIResponse } from "../../../../util/APIResponse";
import HTTPMethod from "../../../../util/HTTPMethod";
import { Requestor } from "../../../../util/Requestor";

export interface Params {}
export interface Input extends RequestInput {}

export interface Output {
  success: boolean;
}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/auth",
  method: HTTPMethod.DELETE,
  prepareInput: ({}: Params): Input => ({}),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
