import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../util/AuthContext";
import { CommonTranslation, Translations } from "../../I18N";
import { Send } from "../../endpoints/account/account/delete/Endpoint";
import { error, log } from "../../util/Logger";
import { paths } from "../../Paths";
import { usePlans } from "../../util/plans/UsePlan";
import PlanCard, {
  planStatusAvailable,
  planStatusCurrent,
} from "../../pages/plan/PlanCard";
import envVariables from "../../EnvVars";
import { getPlanByName } from "../../util/plans/Plans";

interface AccountViewProps {
  onDeleteAccount?: () => Promise<void>;
  onRevokeAuthentications?: () => Promise<void>;
}

const AccountView: React.FC<AccountViewProps> = ({
  onDeleteAccount,
  onRevokeAuthentications,
}) => {
  const { t } = CommonTranslation();
  const navigate = useNavigate();
  const { updateAuthStatus, logout, account, sendUpdateAccount } = useAuth();
  const [isSendingDelete, setIsSendingDelete] = useState(false);
  const [isSendingRevoke, setIsSendingRevoke] = useState(false);

  const { currentPlan, isLoading, displayPlans, sendPlanUpdate } = usePlans();

  const useBillingPortal = (account: any) => {
    const [billingPortalURL, setBillingPortalURL] = useState("");

    useEffect(() => {
      const loadBillingPortalUrl = async () => {
        if (getPlanByName(currentPlan)?.isStatic) {
          return;
        }

        if (!account || billingPortalURL) {
          return;
        }

        try {
          await sendPlanUpdate(
            account.account.plan_name,
            (billingPortalURL: string | undefined, _: string | undefined) => {
              if (billingPortalURL) {
                setBillingPortalURL(billingPortalURL);
              }
            }
          );
        } catch (error) {
          console.error("Failed to load billing portal URL:", error);
        }
      };

      loadBillingPortalUrl();
    }, [account, billingPortalURL]);

    return { billingPortalURL };
  };

  const { billingPortalURL } = useBillingPortal(account);

  const handleDeleteAccount = async () => {
    const confirmDelete = window.confirm(
      t(Translations.AccountView.confirmDelete)
    );
    if (confirmDelete) {
      await deleteAccount();
      onDeleteAccount && onDeleteAccount();
    }
  };

  const deleteAccount = async () => {
    log("Deleting account");
    setIsSendingDelete(true);
    const apiResponse = await Send({});
    log("Delete account response:", apiResponse);

    if (apiResponse.status === 200) {
      log("Delete account success");
    } else {
      error("Delete account failed");
    }

    await updateAuthStatus();
    setIsSendingDelete(false);
    navigate(paths.index);
  };

  const handleRevokeAuthentications = async () => {
    const confirmRevoke = window.confirm(
      t(Translations.AccountView.confirmRevokeAuthentications)
    );
    if (confirmRevoke) {
      setIsSendingRevoke(true);
      await revokeAuthentications();
      onRevokeAuthentications && (await onRevokeAuthentications());
      setIsSendingRevoke(false);
    }
  };

  const revokeAuthentications = async () => {
    await logout();
    navigate(paths.index);
  };

  const setPreferenceSendEmailOnChatMessage = async (isChecked: boolean) => {
    log(account);
    if (!account) {
      return;
    }

    log("Set preference send email on chat message:", isChecked);
    log(account.account.preference_send_email_on_chat_message);
    await sendUpdateAccount({
      preference_send_email_on_chat_message: isChecked,
    });
  };

  return (
    <div>
      {envVariables.SHOW_PLANS ? (
        <div className="mb-3">
          <h3>{t(Translations.AccountView.plan)}</h3>
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: t(Translations.AccountView.plansBody, {
                  plansPath: paths.selectPlan,
                }),
              }}
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
              gap: "20px",
              padding: "20px",
            }}
          >
            {displayPlans.map((plan, index) => {
              if (plan.id !== currentPlan) {
                return null;
              }
              return (
                <PlanCard
                  key={index}
                  name={plan.name}
                  price={plan.price}
                  features={plan.features}
                  plan={{
                    status:
                      currentPlan === plan.id
                        ? planStatusCurrent
                        : planStatusAvailable,
                  }}
                  isLoading={isLoading}
                  canSelect={true}
                  onSelect={plan.onSelect}
                />
              );
            })}
          </div>
          <div>
            <a
              href={billingPortalURL}
              target="_blank"
              style={{
                visibility: billingPortalURL.length > 0 ? "visible" : "hidden",
              }}
            >
              {t(Translations.AccountView.seeBillingDetails)}
            </a>
          </div>
        </div>
      ) : null}
      <div>
        <h3>{t(Translations.AccountView.accountManagement)}</h3>
        <Form>
          <Form.Group as={Row} className="mb-4">
            <Col sm={12}>
              <Form.Check
                type="switch"
                id="preference-send-email-on-chat-message"
                checked={account?.account.preference_send_email_on_chat_message}
                onChange={(e) =>
                  setPreferenceSendEmailOnChatMessage(e.target.checked)
                }
                className="custom-switch"
                label={
                  <span className="mx-3">
                    {t(
                      Translations.AccountView
                        .toggleChatMessageEmailNotifications
                    )}
                  </span>
                }
              />
            </Col>
          </Form.Group>
          <Row>
            <Col sm={12} md={3} className="mb-2 mb-md-0">
              <Button
                variant="warning"
                onClick={handleRevokeAuthentications}
                className="w-100"
                style={{ flex: "0 0 100%" }}
                disabled={isSendingRevoke}
              >
                {isSendingRevoke
                  ? t(Translations.FormField.sending)
                  : t(Translations.AccountView.revokeAuthentications)}
              </Button>
              <Button
                variant="danger"
                onClick={handleDeleteAccount}
                className="w-100 mt-3"
                style={{ flex: "0 0 100%" }}
                disabled={isSendingDelete}
              >
                {isSendingDelete
                  ? t(Translations.FormField.sending)
                  : t(Translations.AccountView.deleteAccount)}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AccountView;
