export const indexPageKey = "IndexPage";

interface IndexPageTranslations {
  welcome: string;
  description: string;
  reportItem: string;
  registerUser: string;
  readyToStart: string;
  howDoesItWorkTitle: string;
  howDoesItWorkBody: string;
  readMoreAt: string;
  tutorialRegisterItemTitle: string;
  tutorialRegisterItemBody: string;
  tutorialAttachItemCodeTitle: string;
  tutorialAttachItemCodeBody: string;
  tutorialLostFoundTitle: string;
  tutorialLostFoundBody: string;
  tutorialRetrieveItemTitle: string;
  tutorialRetrieveItemBody: string;
  useCasesTitle: string;
  useCasesBody: string;
  useCasesTexts: [{ title: string; body: string }];
}

const indexPageModel: IndexPageTranslations = {
  welcome: "",
  description: "",
  reportItem: "",
  registerUser: "",
  readyToStart: "",
  howDoesItWorkTitle: "",
  howDoesItWorkBody: "",
  readMoreAt: "",
  tutorialRegisterItemTitle: "",
  tutorialRegisterItemBody: "",
  tutorialAttachItemCodeTitle: "",
  tutorialAttachItemCodeBody: "",
  tutorialLostFoundTitle: "",
  tutorialLostFoundBody: "",
  tutorialRetrieveItemTitle: "",
  tutorialRetrieveItemBody: "",
  useCasesTitle: "",
  useCasesBody: "",
  useCasesTexts: [{ title: "", body: "" }],
};

const privacyPolicyPageKey = "PrivacyPolicyPage";

interface PrivacyPolicyPageTranslations {}

const privacyPolicyPageModel: PrivacyPolicyPageTranslations = {};

const cookiePolicyPageKey = "CookiePolicyPage";

interface CookiePolicyPageTranslations {}

const cookiePolicyPageModel: CookiePolicyPageTranslations = {};

const loginPageKey = "LoginPage";

interface LoginPageTranslations {
  loginWithEmail: string;
  emailLoginInstructions: string;
  loginWithGoogle: string;
  enterMagicCode: string;
  verifyMagicCode: string;
  magicCodeInstructions: string;
}

const loginPageModel: LoginPageTranslations = {
  loginWithEmail: "",
  emailLoginInstructions: "",
  loginWithGoogle: "",
  enterMagicCode: "",
  verifyMagicCode: "",
  magicCodeInstructions: "",
};

const loginFormKey = "LoginForm";

interface LoginFormTranslations {
  alreadyHaveMagicCode: string;
  doNotHaveMagicCode: string;
}

const loginFormModel: LoginFormTranslations = {
  alreadyHaveMagicCode: "",
  doNotHaveMagicCode: "",
};

const dashboardPageKey = "DashboardPage";

interface DashboardPageTranslations {
  dashboard: string;
  navItems: string;
  navAccount: string;
  basicView: string;
  advancedView: string;
}

const dashboardPageModel: DashboardPageTranslations = {
  dashboard: "",
  navItems: "",
  navAccount: "",
  basicView: "",
  advancedView: "",
};

const itemRegistrationPageKey = "ItemRegistrationPage";

interface ItemRegistrationPageTranslations {
  registerNewItem: string;
  maximumNumberOfItemsReached: string;
  registerItem: string;
  itemsRegisterSuccess: string;
}

const itemRegistrationPageModel: ItemRegistrationPageTranslations = {
  registerNewItem: "",
  maximumNumberOfItemsReached: "",
  registerItem: "",
  itemsRegisterSuccess: "",
};

const ItemDetailPageKey = "ItemDetailPage";

interface ItemDetailPageTranslations {
  itemDetails: string;
  confirmDelete: string;
  confirmItemCodeRegeneration: string;
  regenerateItemCode: string;
  backToDashboard: string;
}

const itemDetailPageModel: ItemDetailPageTranslations = {
  itemDetails: "",
  confirmDelete: "",
  confirmItemCodeRegeneration: "",
  regenerateItemCode: "",
  backToDashboard: "",
};

export const enterItemCodePageKey = "EnterItemCodePage";

interface EnterItemCodePageTranslations {
  title: string;
  instructions: string;
  itemCode: string;
  enterChat: string;
}

const enterItemCodePageModel: EnterItemCodePageTranslations = {
  title: "",
  instructions: "",
  itemCode: "",
  enterChat: "",
};

export const chatPageKey = "ChatPage";

interface ChatPageTranslations {
  title: string;
  itemCodeNotProvided: string;
  instructions: string;
}

const chatPageModel: ChatPageTranslations = {
  title: "",
  itemCodeNotProvided: "",
  instructions: "",
};

const accountVerifyPageKey = "AccountVerifyPage";

interface AccountVerifyPageTranslations {
  title: string;
  verificationFailed: string;
  errorHelp: string;
  verifyingAccount: string;
  verifyAccountSuccess: string;
}

const accountVerifyPageModel: AccountVerifyPageTranslations = {
  title: "",
  verificationFailed: "",
  errorHelp: "",
  verifyingAccount: "",
  verifyAccountSuccess: "",
};

const checkEmailPageKey = "CheckEmailPage";

interface CheckEmailPageTranslations {
  registrationSuccessful: string;
  pleaseCheckYourEmail: string;
}

const checkEmailPageModel: CheckEmailPageTranslations = {
  registrationSuccessful: "",
  pleaseCheckYourEmail: "",
};

const magicCodeVerifyPageKey = "MagicCodeVerifyPage";

interface MagicCodeVerifyPageTranslations {
  title: string;
  verificationFailed: string;
  errorHelp: string;
  verifyingMagicCode: string;
  verifyMagicCodeSuccess: string;
  noMagicCodeProvided: string;
}

const magicCodeVerifyPageModel: MagicCodeVerifyPageTranslations = {
  title: "",
  verificationFailed: "",
  errorHelp: "",
  verifyingMagicCode: "",
  verifyMagicCodeSuccess: "",
  noMagicCodeProvided: "",
};

const oAuthCallbackPageKey = "OAuthCallbackPage";

interface OAuthCallbackPageTranslations {
  errorProcessingRequest: string;
}

const oAuthCallbackPageModel: OAuthCallbackPageTranslations = {
  errorProcessingRequest: "",
};

const userRegistrationErrorPageKey = "UserRegistrationErrorPage";

interface UserRegistrationErrorPageTranslations {
  errorDuringRegistration: string;
}

const userRegistrationErrorPageModel: UserRegistrationErrorPageTranslations = {
  errorDuringRegistration: "",
};

export const faqPageKey = "FAQPage";

interface FAQPageTranslations {
  title: string;
  description: string;
  questions: { question: string; answer: string }[];
}

const faqPageModel: FAQPageTranslations = {
  title: "",
  description: "",
  questions: [],
};

const selectPlanPageKey = "SelectPlanPage";

interface SelectPlanPageTranslations {
  title: string;
}

const selectPlanPageModel: SelectPlanPageTranslations = {
  title: "",
};

const notFoundPageKey = "NotFoundPage";

interface NotFoundPageTranslations {
  title: string;
  description: string;
}

const notFoundPageModel: NotFoundPageTranslations = {
  title: "",
  description: "",
};

const NavigationKey = "Navigation";

interface NavigationTranslations {
  home: string;
  dashboard: string;
  register: string;
  login: string;
  logout: string;
  privacyPolicy: string;
  cookiePolicy: string;
  faq: string;
  contact: string;
  reportItem: string;
  selectPlan: string;
}

const NavigationModel: NavigationTranslations = {
  home: "",
  dashboard: "",
  register: "",
  login: "",
  logout: "",
  privacyPolicy: "",
  cookiePolicy: "",
  faq: "",
  contact: "",
  reportItem: "",
  selectPlan: "",
};

export const chatSectionKey = "ChatSection";

interface ChatSectionTranslations {
  itemNotFoundWithItemCode: string;
  errorLoadingMessages: string;
  owner: string;
  finder: string;
  typeMessage: string;
  refresh: string;
  viewOlderMessages: string;
  noMoreMessages: string;
  failedToSendMessage: string;
}

const chatSectionModel: ChatSectionTranslations = {
  itemNotFoundWithItemCode: "",
  errorLoadingMessages: "",
  owner: "",
  finder: "",
  typeMessage: "",
  refresh: "",
  viewOlderMessages: "",
  noMoreMessages: "",
  failedToSendMessage: "",
};

const RegisteredItemsListKey = "RegisteredItemsList";

interface RegisteredItemsListTranslations {
  registeredItems: string;
  viewDetails: string;
  noItems: string;
  unreadMessages: string;
  noUnreadMessages: string;
  unread: string;
  uploadCSV: string;
  sampleLink: string;
  sampleFilename: string;
  uploadCSVInstructions: string;
  deleteSelected: string;
  confirmDelete: string;
  deleteSelectedSuccess: string;
  deleteSelectedFailure: string;
  exportSelected: string;
  exportSelectedSuccess: string;
  exportSelectedFailure: string;
  downloadCSVFilename: string;
  totalItems: string;
  itemCountOverMax: string;
}

const RegisteredItemsListModel: RegisteredItemsListTranslations = {
  registeredItems: "",
  viewDetails: "",
  noItems: "",
  unreadMessages: "",
  noUnreadMessages: "",
  unread: "",
  uploadCSV: "",
  sampleLink: "",
  sampleFilename: "",
  uploadCSVInstructions: "",
  deleteSelected: "",
  confirmDelete: "",
  deleteSelectedSuccess: "",
  deleteSelectedFailure: "",
  exportSelected: "",
  exportSelectedSuccess: "",
  exportSelectedFailure: "",
  downloadCSVFilename: "",
  totalItems: "",
  itemCountOverMax: "",
};

const ItemDetailKey = "ItemDetail";

interface ItemDetailTranslations {
  itemCode: string;
  itemCodeDescription: string;
  copy: string;
  copyItemCode: string;
  copyChatURL: string;
  copiedToClipboard: string;
  hideItemCode: string;
  showItemCode: string;
  itemName: "";
  itemDescription: "";
  itemDescriptionInstructions: string;
  itemPublicInfo: "";
  itemPublicInfoInstructions: string;
  itemNotFound: string;
  itemChat: string;
  itemChatTitle: string;
  itemChatURL: string;
  noDescriptionAvailable: string;
  noPublicInfoAvailable: string;
}

const ItemDetailModel: ItemDetailTranslations = {
  itemCode: "",
  itemCodeDescription: "",
  copy: "",
  copyItemCode: "",
  copyChatURL: "",
  copiedToClipboard: "",
  hideItemCode: "",
  showItemCode: "",
  itemName: "",
  itemDescription: "",
  itemDescriptionInstructions: "",
  itemPublicInfo: "",
  itemPublicInfoInstructions: "",
  itemNotFound: "",
  itemChat: "",
  itemChatTitle: "",
  itemChatURL: "",
  noDescriptionAvailable: "",
  noPublicInfoAvailable: "",
};

const AccountViewKey = "AccountView";

interface AccountViewTranslations {
  title: string;
  deleteAccount: string;
  confirmDelete: string;
  revokeAuthentications: string;
  confirmRevokeAuthentications: string;
  plan: string;
  plansBody: string;
  accountManagement: string;
  toggleChatMessageEmailNotifications: string;
  seeBillingDetails: string;
}

const AccountViewModel: AccountViewTranslations = {
  title: "",
  deleteAccount: "",
  confirmDelete: "",
  revokeAuthentications: "",
  confirmRevokeAuthentications: "",
  plan: "",
  plansBody: "",
  accountManagement: "",
  toggleChatMessageEmailNotifications: "",
  seeBillingDetails: "",
};

export const captchaKey = "Captcha";

interface CaptchaTranslations {
  failedToGet: string;
  failedToLoad: string;
}

const captchaModel: CaptchaTranslations = {
  failedToGet: "",
  failedToLoad: "",
};

export const formFieldKey = "FormField";

interface FormFieldTranslations {
  send: string;
  sending: string;
  email: string;
  pleaseEnterEmail: string;
  login: string;
  verify: string;
  register: string;
  fieldMustBeAtLeastXCharacters: string;
  fieldMustBeLessThanXCharacters: string;
  cancel: string;
  edit: string;
  delete: string;
  back: string;
  save: string;
  true: string;
  false: string;
  yes: string;
  no: string;
}

const formFieldModel: FormFieldTranslations = {
  send: "",
  sending: "",
  email: "",
  pleaseEnterEmail: "",
  login: "",
  verify: "",
  register: "",
  fieldMustBeAtLeastXCharacters: "",
  fieldMustBeLessThanXCharacters: "",
  cancel: "",
  edit: "",
  delete: "",
  back: "",
  save: "",
  true: "",
  false: "",
  yes: "",
  no: "",
};

const genericKey = "Generic";

interface GenericTranslations {
  loading: string;
  sending: string;
  creating: string;
  created: string;
  updated: string;
  hide: string;
  show: string;
  showInstructions: string;
  hideInstructions: string;
  unavailable: string;
}

const genericModel: GenericTranslations = {
  loading: "",
  sending: "",
  creating: "",
  created: "",
  updated: "",
  hide: "",
  show: "",
  showInstructions: "",
  hideInstructions: "",
  unavailable: "",
};

const errorKey = "Error";

interface ErrorTranslations {
  tryAgainLater: string;
  error: string;
  errorMessage: string;
  thatsAllWeKnow: string;
}

const errorModel: ErrorTranslations = {
  tryAgainLater: "",
  error: "",
  errorMessage: "",
  thatsAllWeKnow: "",
};

const languageSelectorKey = "LanguageSelector";

interface LanguageSelectorTranslations {
  language: string;
  english: string;
  finnish: string;
}

const languageSelectorModel: LanguageSelectorTranslations = {
  language: "",
  english: "",
  finnish: "",
};

const userRegistrationFormKey = "UserRegistrationForm";

interface UserRegistrationFormTranslations {
  title: string;
  registerWithGoogle: string;
}

const userRegistrationFormModel: UserRegistrationFormTranslations = {
  title: "",
  registerWithGoogle: "",
};

const cookieConentPopupKey = "CookieConsentPopup";

interface CookieConsentPopupTranslations {
  mainTitle: string;
  mainBody: string;
  preferencesTitle: string;
  savePreferences: string;
  allowAll: string;
  rejectAll: string;
  preferences: string;
  essentialCookies: string;
  analyticsCookies: string;
}

const cookieConsentPopupModel: CookieConsentPopupTranslations = {
  mainTitle: "",
  mainBody: "",
  preferencesTitle: "",
  savePreferences: "",
  allowAll: "",
  rejectAll: "",
  preferences: "",
  essentialCookies: "",
  analyticsCookies: "",
};

export const plansKey = "Plans";

interface PlansTranslations {
  choosePlan: string;
  currentPlan: string;
  pendingPlan: string;
  planEnds: string;
  planStarts: string;
  planStarted: string;
  cancelDowngrade: string;
  nextPayment: string;
  noPaymentsLeft: string;
  planFree: string;
  priceFree: string;
  planBasic: string;
  planPro: string;
  planEnterprise: string;
  priceMonthOrYear: string;
  priceCustom: string;
  featuresFree: string[];
  featuresBasic: string[];
  featuresPro: string[];
  featuresEnterprise: string[];
}

const plansModel: PlansTranslations = {
  choosePlan: "",
  currentPlan: "",
  pendingPlan: "",
  planEnds: "",
  planStarts: "",
  planStarted: "",
  cancelDowngrade: "",
  nextPayment: "",
  noPaymentsLeft: "",
  planFree: "",
  priceFree: "",
  planBasic: "",
  planPro: "",
  planEnterprise: "",
  priceMonthOrYear: "",
  priceCustom: "",
  featuresFree: [],
  featuresBasic: [],
  featuresPro: [],
  featuresEnterprise: [],
};

const csvUploadKey = "CSVUpload";

interface CSVUploadTranslations {
  upload: string;
}

const csvUploadModel: CSVUploadTranslations = {
  upload: "",
};

function createTranslationKeys<T extends object>(base: string, model: T): T {
  const keys = Object.keys(model) as Array<keyof T>;
  const record: Partial<T> = {};
  keys.forEach((key) => {
    record[key] = `${base}.${String(key)}` as T[keyof T];
  });
  return record as T;
}

export const Translations: {
  IndexPage: IndexPageTranslations;
  PrivacyPolicyPage: PrivacyPolicyPageTranslations;
  CookiePolicyPage: CookiePolicyPageTranslations;
  LoginPage: LoginPageTranslations;
  LoginForm: LoginFormTranslations;
  DashboardPage: DashboardPageTranslations;
  ItemRegistrationPage: ItemRegistrationPageTranslations;
  ItemDetailPage: ItemDetailPageTranslations;
  EnterItemCodePage: EnterItemCodePageTranslations;
  ChatPage: ChatPageTranslations;
  AccountVerifyPage: AccountVerifyPageTranslations;
  CheckEmailPage: CheckEmailPageTranslations;
  MagicCodeVerifyPage: MagicCodeVerifyPageTranslations;
  OAuthCallbackPage: OAuthCallbackPageTranslations;
  UserRegistrationErrorPage: UserRegistrationErrorPageTranslations;
  FAQPage: FAQPageTranslations;
  SelectPlanPage: SelectPlanPageTranslations;
  NotFoundPage: NotFoundPageTranslations;
  Navigation: NavigationTranslations;
  ChatSection: ChatSectionTranslations;
  RegisteredItemsList: RegisteredItemsListTranslations;
  ItemDetail: ItemDetailTranslations;
  AccountView: AccountViewTranslations;
  Captcha: CaptchaTranslations;
  FormField: FormFieldTranslations;
  Generic: GenericTranslations;
  Error: ErrorTranslations;
  LanguageSelector: LanguageSelectorTranslations;
  UserRegistrationForm: UserRegistrationFormTranslations;
  CookieConsentPopup: CookieConsentPopupTranslations;
  Plans: PlansTranslations;
  CSVUpload: CSVUploadTranslations;
} = {
  IndexPage: createTranslationKeys(indexPageKey, indexPageModel),
  PrivacyPolicyPage: createTranslationKeys(
    privacyPolicyPageKey,
    privacyPolicyPageModel
  ),
  CookiePolicyPage: createTranslationKeys(
    cookiePolicyPageKey,
    cookiePolicyPageModel
  ),
  LoginPage: createTranslationKeys(loginPageKey, loginPageModel),
  LoginForm: createTranslationKeys(loginFormKey, loginFormModel),
  DashboardPage: createTranslationKeys(dashboardPageKey, dashboardPageModel),
  ItemRegistrationPage: createTranslationKeys(
    itemRegistrationPageKey,
    itemRegistrationPageModel
  ),
  ItemDetailPage: createTranslationKeys(ItemDetailPageKey, itemDetailPageModel),
  EnterItemCodePage: createTranslationKeys(
    enterItemCodePageKey,
    enterItemCodePageModel
  ),
  ChatPage: createTranslationKeys(chatPageKey, chatPageModel),
  AccountVerifyPage: createTranslationKeys(
    accountVerifyPageKey,
    accountVerifyPageModel
  ),
  CheckEmailPage: createTranslationKeys(checkEmailPageKey, checkEmailPageModel),
  MagicCodeVerifyPage: createTranslationKeys(
    magicCodeVerifyPageKey,
    magicCodeVerifyPageModel
  ),
  OAuthCallbackPage: createTranslationKeys(
    oAuthCallbackPageKey,
    oAuthCallbackPageModel
  ),
  UserRegistrationErrorPage: createTranslationKeys(
    userRegistrationErrorPageKey,
    userRegistrationErrorPageModel
  ),
  FAQPage: createTranslationKeys(faqPageKey, faqPageModel),
  SelectPlanPage: createTranslationKeys(selectPlanPageKey, selectPlanPageModel),
  NotFoundPage: createTranslationKeys(notFoundPageKey, notFoundPageModel),
  Navigation: createTranslationKeys(NavigationKey, NavigationModel),
  ChatSection: createTranslationKeys(chatSectionKey, chatSectionModel),
  RegisteredItemsList: createTranslationKeys(
    RegisteredItemsListKey,
    RegisteredItemsListModel
  ),
  ItemDetail: createTranslationKeys(ItemDetailKey, ItemDetailModel),
  AccountView: createTranslationKeys(AccountViewKey, AccountViewModel),
  Captcha: createTranslationKeys(captchaKey, captchaModel),
  FormField: createTranslationKeys(formFieldKey, formFieldModel),
  Generic: createTranslationKeys(genericKey, genericModel),
  Error: createTranslationKeys(errorKey, errorModel),
  LanguageSelector: createTranslationKeys(
    languageSelectorKey,
    languageSelectorModel
  ),
  UserRegistrationForm: createTranslationKeys(
    userRegistrationFormKey,
    userRegistrationFormModel
  ),
  CookieConsentPopup: createTranslationKeys(
    cookieConentPopupKey,
    cookieConsentPopupModel
  ),
  Plans: createTranslationKeys(plansKey, plansModel),
  CSVUpload: createTranslationKeys(csvUploadKey, csvUploadModel),
};
