export type Predicate =
  | "GT"
  | "GE"
  | "EQ"
  | "NE"
  | "LT"
  | "LE"
  | "GR"
  | "IN"
  | "NOT_IN";

export const greater: Predicate = "GT";
export const greaterEq: Predicate = "GE";
export const equal: Predicate = "EQ";
export const notEqual: Predicate = "NE";
export const less: Predicate = "LT";
export const lessEq: Predicate = "LE";
export const predicateIn: Predicate = "IN";
export const predicateNotIn: Predicate = "NOT_IN";
