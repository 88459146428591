import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/layout/Navbar';
import Footer from '../../components/layout/Footer';
import { paths } from '../../Paths';
import { CommonTranslation, Translations } from '../../I18N';
import Card from '../../components/layout/Card';

const EnterItemCodePage: React.FC = () => {
    const [itemCode, setItemCode] = useState('');
    const navigate = useNavigate();
    const { t } = CommonTranslation();
    const [isSendingItemCode, setIsSendingItemCode] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSendingItemCode(true);
        if (itemCode) {
            navigate(`${paths.chat}/${itemCode}`);
        }
        setIsSendingItemCode(false);
    };

    return (
        <div className="full-height">
            <Navbar />
            <Card
                title={t(Translations.EnterItemCodePage.title)}
                onSubmit={handleSubmit}
            >
                <div className="mb-3">
                    <p>{t(Translations.EnterItemCodePage.instructions)}</p>
                    <label htmlFor="itemCode" className="form-label">{t(Translations.EnterItemCodePage.itemCode)}:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="itemCode"
                        value={itemCode}
                        onChange={(e) => setItemCode(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary" disabled={isSendingItemCode}>
                    {isSendingItemCode ? t(Translations.FormField.sending) : t(Translations.EnterItemCodePage.enterChat)}
                </button>
            </Card>
            <Footer />
        </div>
    );
};

export default EnterItemCodePage;
