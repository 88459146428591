import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/layout/Navbar';
import Footer from '../../components/layout/Footer';
import LoginForm from '../../components/account/LoginForm';
import { Send as CreateSend } from '../../endpoints/account/magic_link_session/create/Endpoint';
import { Send as VerifySend } from '../../endpoints/account/magic_link_session/verify_short/Endpoint';
import { paths } from '../../Paths';
import { error, log } from '../../util/Logger';

interface LoginPageProps {
    onLoginSuccess: () => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLoginSuccess }) => {
    const navigate = useNavigate();

    const handleSendEmail = async (
        email: string,
        recaptchaResponse: string,
    ) => {
        log("Creating magic link session");

        const apiResponse = await CreateSend(
            {
                email: email,
                recaptchaResponse: recaptchaResponse,
            },
        )

        log("Magic link session creation response:", apiResponse);

        if (apiResponse.status === 200) {
            log("Magic link session creation success");
        } else {
            error("Magic link session creation failed");
        }
    };

    const handleCodeVerify = async (email: string, code: string) => {
        log("Verifying magic link session");

        const apiResponse = await VerifySend(
            {
                email: email,
                code: code,
            },
        )

        log("Magic link session verification response:", apiResponse);

        if (apiResponse.status === 200) {
            log("Magic link session verification success");

            onLoginSuccess();
            navigate(paths.dashboard);
        } else {
            error("Magic link session verification failed");
        }
    };

    return (
        <div className="full-height">
            <Navbar />
            <div className="container flex-grow-1">
                <LoginForm
                    onEmailSend={handleSendEmail}
                    onCodeVerify={handleCodeVerify}
                />
            </div>
            <Footer />
        </div>
    );
};

export default LoginPage;
