import { useEffect, useState } from 'react';
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import './ContactPage.css';
import i18n from 'i18next';
import envVariables from '../EnvVars';
import { CommonTranslation } from '../I18N';
import { Translations } from '../locales/translations';

const ContactPage = () => {
    const { t } = CommonTranslation();

    const formURLs: { [key: string]: string } = {
        en: envVariables.CONTACT_FORM_URL_EN,
        fi: envVariables.CONTACT_FORM_URL_FI,
    };

    const [currentFormUrl, setCurrentFormUrl] = useState(
        formURLs[i18n.language || 'en'],
    );

    useEffect(() => {
        function handleLanguageChange() {
            const newLang = i18n.language || 'en';
            setCurrentFormUrl(formURLs[newLang]);
        }

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    return (
        <div className="full-height">
            <Navbar />
            <div className="iframe-container">
                <iframe
                    src={currentFormUrl}
                    allowFullScreen>{t(Translations.Generic.loading)}</iframe>
            </div>
            <Footer />
        </div>
    );
};

export default ContactPage;
