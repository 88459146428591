export const planFree = "free";
export const planBasic = "basic";
export const planPro = "pro";
export const planEnterprise = "enterprise";

export interface PlanDetail {
  isStatic: boolean;
  invoicePerMonth: number;
  featureMaxItemCount: number;
  featureItemtable: boolean;
  featureItemPublicInfo: boolean;
  featureUploadCSV: boolean;
}

export const planDetails: { [key: string]: PlanDetail } = {
  [planFree]: {
    isStatic: false,
    invoicePerMonth: 0,
    featureMaxItemCount: 3,
    featureItemtable: false,
    featureItemPublicInfo: false,
    featureUploadCSV: false,
  },
  [planBasic]: {
    isStatic: false,
    invoicePerMonth: 5,
    featureMaxItemCount: 20,
    featureItemtable: false,
    featureItemPublicInfo: true,
    featureUploadCSV: false,
  },
  [planPro]: {
    isStatic: false,
    invoicePerMonth: 15,
    featureMaxItemCount: 100,
    featureItemtable: true,
    featureItemPublicInfo: true,
    featureUploadCSV: true,
  },
  [planEnterprise]: {
    isStatic: true,
    invoicePerMonth: 0,
    featureMaxItemCount: 0,
    featureItemtable: true,
    featureItemPublicInfo: true,
    featureUploadCSV: true,
  },
};

export const getPlanByName = (name?: string) => {
  if (!name) {
    return null;
  }
  return planDetails[name];
};
