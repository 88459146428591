export interface PaginationInfo {
    totalFetched: number;
    limit: number;
    offset: number;
    updateOffset: (newOffset: number) => void;
    setMoreAvailable: (moreAvailable: boolean) => void;
}

export const getPaginatedData = <T extends Record<string, any>>(
    data: T[],
    currentData: T[],
    updateData: (newData: T[]) => void,
    uniqueKey: keyof T,
    sortKey: keyof T,
    sortOrder: 'asc' | 'desc' = 'asc',
    paginationInfo: PaginationInfo
) => {
    // Merge data based on the fetch type
    const mergedData = mergePaginatedData(
        currentData,
        data,
        uniqueKey,
        sortKey,
        sortOrder
    );

    // Update the data state with the merged data
    updateData(mergedData);

    // Check if more items are available
    const moreAvailable = data.length >= paginationInfo.limit;
    paginationInfo.setMoreAvailable(moreAvailable);

    // Update offset if more items are available
    if (moreAvailable) {
        paginationInfo.updateOffset(paginationInfo.offset + data.length);
    }
};

export const mergePaginatedData = <T extends Record<string, any>>(
    existingData: T[],
    newData: T[],
    uniqueKey: keyof T,
    sortKey: keyof T,
    sortOrder: 'asc' | 'desc' = 'asc'
): T[] => {
    const dataMap = new Map<any, T>();

    // Function to add or update data in the map
    const updateData = (data: T) => {
        const existing = dataMap.get(data[uniqueKey]);
        if (!existing || data[sortKey] > existing[sortKey]) {
            dataMap.set(data[uniqueKey], data);
        }
    };

    // Populate map with existing and new data
    existingData.forEach(updateData);
    newData.forEach(updateData);

    // Convert map to array and sort it
    const sortedData = Array.from(dataMap.values());
    sortedData.sort((a, b) => {
        return sortOrder === 'asc' ?
            a[sortKey] - b[sortKey] :
            b[sortKey] - a[sortKey];
    });
    return sortedData;
};