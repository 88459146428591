import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/layout/Navbar';
import Footer from '../../components/layout/Footer';
import ChatSection from '../../components/dashboard/ChatSection';
import './ChatPage.css';
import { CommonTranslation, Translations, GetCommonTranslation } from '../../I18N';
import { chatPageKey } from '../../locales/translations';
import { Trans } from 'react-i18next';

const ChatPage: React.FC = () => {
    const { itemCode } = useParams<{ itemCode: string }>();
    const { t } = CommonTranslation();
    const [success, setSuccess] = useState(false);

    if (!itemCode) {
        return <div>{t(Translations.ChatPage.itemCodeNotProvided)}</div>;
    }

    const InstructionsComponent = ({ itemCode }: { itemCode: string }) => (
        <b><span className="code-text text-uppercase">{itemCode}</span></b>
    );

    return (
        <div className="full-height">
            <Navbar />
            <div className="container mt-5 flex-grow-1">
                <h3>{t(Translations.ChatPage.title)}</h3>
                {success && (
                    <p>
                        <Trans
                            i18nKey={GetCommonTranslation(chatPageKey, 'instructions')}
                            components={[<InstructionsComponent itemCode={itemCode} />]}
                        />
                    </p>
                )}
                <ChatSection
                    itemCode={itemCode}
                    onSuccess={() => setSuccess(true)}
                    useReCaptcha={true}
                />
            </div>
            <Footer />
        </div>
    );
};

export default ChatPage;
