import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import { useTranslation } from 'react-i18next';
import { indexPageKey, Translations } from '../locales/translations';
import { useNavigate } from 'react-router-dom';
import { paths } from '../Paths';
import './IndexPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faTag, faSearch, faHandshake, faLightbulb, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Button, Card, } from 'react-bootstrap';

const IndexPage = () => {
    const { t } = useTranslation('Common');
    const navigate = useNavigate();

    const goToUserRegistrationPage = () => {
        navigate(paths.register);
    };

    const goToItemCodePage = () => {
        navigate(paths.itemCode);
    };

    const steps = [
        {
            id: 1,
            icon: faClipboardList,
            title: Translations.IndexPage.tutorialRegisterItemTitle,
            body: Translations.IndexPage.tutorialRegisterItemBody
        },
        {
            id: 2,
            icon: faTag,
            title: Translations.IndexPage.tutorialAttachItemCodeTitle,
            body: Translations.IndexPage.tutorialAttachItemCodeBody
        },
        {
            id: 3,
            icon: faSearch,
            title: Translations.IndexPage.tutorialLostFoundTitle,
            body: Translations.IndexPage.tutorialLostFoundBody
        },
        {
            id: 4,
            icon: faHandshake,
            title: Translations.IndexPage.tutorialRetrieveItemTitle,
            body: Translations.IndexPage.tutorialRetrieveItemBody
        },
    ]

    const cases = t(`${indexPageKey}.useCasesTexts`, { returnObjects: true }) as Array<{ title: string; body: string }>;

    const HeroSection = () => {
        return (
            <div className="hero-section text-center">
                <h1>{t(Translations.IndexPage.welcome)}</h1>
                <p>{t(Translations.IndexPage.description)}</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-auto mb-3">
                        <Button variant="primary btn-lg" onClick={goToUserRegistrationPage}>
                            {t(Translations.IndexPage.registerUser)}
                        </Button>
                    </div>
                    <div className="col-12 col-md-auto mb-3">
                        <Button variant="primary btn-lg" onClick={goToItemCodePage}>
                            {t(Translations.IndexPage.reportItem)}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    interface Step {
        id: number;
        title: string;
        body: string;
        icon: IconDefinition;
    }

    interface HowItWorksProps {
        steps: Step[];
    }

    const HowItWorks: React.FC<HowItWorksProps> = ({ steps }) => {
        return (
            <div className="text-center">
                <h2>{t(Translations.IndexPage.howDoesItWorkTitle)}</h2>
                <div className="intro-cards-container">
                    <Row>
                        {steps.map((card, index) => (
                            <Col xs={12} sm={6} lg={3} key={index} className="intro-card-container">
                                <Card className="intro-card">
                                    <Card.Body>
                                        <FontAwesomeIcon icon={card.icon} className="index-icon mb-2" />
                                        <Card.Title>{t(card.title)}</Card.Title>
                                        <Card.Text>{t(card.body)}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        );
    };

    interface UseCase {
        title: string;
        body: string;
    }

    interface UseCasesProps {
        cases: UseCase[];
    }

    const UseCasesSection: React.FC<UseCasesProps> = ({ cases }) => {
        return (
            <div>
                <h2 className="text-center mt-5 mb-4">{t(Translations.IndexPage.useCasesTitle)}</h2>
                {cases.map((useCase, index) => (
                    <Card key={index} className="mb-4 use-case-card">
                        <Card.Header className="use-case-card-header">
                            <FontAwesomeIcon icon={faLightbulb} className="me-2 index-icon" />
                            <h3>{useCase.title}</h3>
                        </Card.Header>
                        <Card.Body className="use-case-card-body">
                            {useCase.body}
                        </Card.Body>
                    </Card>

                ))}
            </div>
        );
    };

    const FinalCTA = () => {
        return (
            <div className="final-cta text-center mt-5">
                <h2 className="mb-3">{t(Translations.IndexPage.readyToStart)}</h2>
                <Button variant="primary btn-lg" onClick={goToUserRegistrationPage} className="mx-2 mb-2 mb-md-2">
                    {t(Translations.IndexPage.registerUser)}
                </Button>
            </div>
        );
    };

    return (
        <div className="full-height">
            <Navbar />
            <HeroSection />
            <HowItWorks steps={steps} />
            <UseCasesSection cases={cases} />
            <FinalCTA />
            <Footer />
        </div>
    );
};

export default IndexPage;

