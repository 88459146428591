import {
  RequestInput,
  EndpointDefinition,
  RequestEndpoint,
} from "../../../util/Endpoints";
import { APIResponse } from "../../../util/APIResponse";
import HTTPMethod from "../../../util/HTTPMethod";
import { Requestor } from "../../../util/Requestor";

export interface Params {
  item_code: {
    item_code: string;
  };
}

export interface Input extends RequestInput {
  body: Params;
}

export interface Output {
  item_code: {
    id: string;
    created: number;
    code: string;
  };
}

export const Endpoint: EndpointDefinition<Params, Input> = {
  path: "/api/item_code",
  method: HTTPMethod.POST,
  prepareInput: (params: Params): Input => ({
    body: params,
  }),
};

export async function Send(params: Params): Promise<APIResponse<Output>> {
  return RequestEndpoint<Params, Input, Output>(
    Endpoint,
    params,
    new Requestor<Input, Output>(),
    "include"
  );
}
