import React from "react";
import { Button, Card } from "react-bootstrap";
import { CommonTranslation, Translations } from "../../I18N";

interface PlanProps {
  name: string;
  price: string;
  features: string[];
  plan: Plan;
  canSelect: boolean;
  isLoading: boolean;
  onSelect: () => void;
}

type planStatus = "current" | "available";
export const planStatusCurrent = "current";
export const planStatusAvailable = "available";

export interface Plan {
  status: planStatus;
}

const PlanCard: React.FC<PlanProps> = ({
  name,
  price,
  features,
  plan,
  canSelect,
  isLoading,
  onSelect,
}) => {
  const { t } = CommonTranslation();

  const buttonText = (): string => {
    if (isLoading) {
      return t(Translations.Generic.loading);
    } else if (plan?.status === planStatusCurrent) {
      return t(Translations.Plans.currentPlan);
    } else if (!canSelect) {
      return t(Translations.Generic.unavailable);
    } else {
      return t(Translations.Plans.choosePlan);
    }
  };

  return (
    <Card
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "10px",
        textAlign: "center",
      }}
    >
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{price}</Card.Subtitle>
        <hr></hr>
        <ul
          style={{
            paddingLeft: "20px",
            margin: "0",
            listStylePosition: "inside",
          }}
        >
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </Card.Body>
      <Button
        variant={plan.status == planStatusCurrent ? "secondary" : "primary"}
        onClick={onSelect}
        disabled={!canSelect || isLoading || plan.status === planStatusCurrent}
        style={{ marginTop: "auto" }}
      >
        {buttonText()}
      </Button>
    </Card>
  );
};

export default PlanCard;
